/** @jsxImportSource @emotion/react */

import { css, Theme } from "@emotion/react";
import { SvgIconComponent } from "@mui/icons-material";
import { Button, Collapse } from "@mui/material";
import { ActiveNav } from "../../home/Home";
import { pulsingButton } from "../styles/styles";

const button = () =>
  css({
    minHeight: 48,
    margin: 14,
    padding: 14,
  });

const buttonOpenned = (theme: Theme) =>
  css({
    transition: theme.transitions.create(["width", "color", "background-color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: "270px",
    justifyContent: "initial",
  });

const buttonClosed = (theme: Theme) =>
  css({
    transition: theme.transitions.create(["width", "color", "background-color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "35px",
    justifyContent: "center",
  });

const icon = () =>
  css({
    minWidth: 0,
    justifyContent: "center",
  });

interface Props {
  id?: string;
  label: string;
  open: boolean;
  actionType: "route" | "function";
  route?: ActiveNav;
  handleNav?: (nav: ActiveNav) => void;
  activeNav?: ActiveNav;
  function?: () => void;
  icon: SvgIconComponent;
  pulsing?: boolean;
}

function SideBarButtonComponent(props: Props) {
  const { pulsing = false } = props;

  return (
    <Button
      id={props.id}
      color="secondary"
      variant={
        props.actionType === "route" ? (props.activeNav === props.route ? "outlined" : "contained") : "contained"
      }
      css={[button, props.open ? buttonOpenned : buttonClosed, pulsing && pulsingButton]}
      onClick={() => (props.actionType === "function" ? props.function?.() : props.handleNav?.(props.route ?? "table"))}
    >
      <ButtonIcon
        icon={props.icon}
        open={props.open}
      />
      <Collapse
        orientation="horizontal"
        in={props.open}
      >
        <b>{props.label}</b>
      </Collapse>
    </Button>
  );
}

export default SideBarButtonComponent;

interface ButtonIconProps {
  icon: SvgIconComponent;
  open: boolean;
}
function ButtonIcon(props: ButtonIconProps) {
  const { icon: Icon } = props;
  return (
    <Icon
      fontSize="large"
      css={icon}
      style={{ marginRight: props.open ? 24 : "auto" }}
    />
  );
}
