/** @jsxImportSource @emotion/react */

import React, { Dispatch, SetStateAction, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Box, Divider, IconButton, ListItemIcon, useMediaQuery, useTheme } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTranslation } from "react-i18next";
import LangMenuComponent from "./LangMenu";
import ThemeMenuComponent from "./ThemeMenu";
import { AppBarProps } from "../AppBar";
import { Modes } from "../../../styles/themes";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InfoIcon from "@mui/icons-material/Info";
import historyRouter from "../../../router/history-router";
import { listColor } from "../styles/styles";

interface Props extends AppBarProps {
  setOpenAboutDialog: Dispatch<SetStateAction<boolean>>;
}

export default function MenuComponent(props: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [langMenuClick, setLangMenuClick] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    handleClose();
    props.setAppIn(false);
    setTimeout(() => {
      historyRouter.replace("/signout");
    }, props.outDuration);
  };

  return (
    <Box>
      <IconButton
        aria-label="menu-button"
        onClick={handleClick}
      >
        <AccountCircleIcon
          fontSize={isMobile ? "medium" : "large"}
          color="secondary"
        />
      </IconButton>
      <Menu
        id="navMenu"
        PaperProps={{ sx: { width: "160px" } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setLangMenuClick(true)}>
          <LangMenuComponent
            langMenuClick={langMenuClick}
            setLangMenuClick={setLangMenuClick}
          />
        </MenuItem>
        <MenuItem onClick={() => props.setThemeMode((t) => (t === Modes.light ? Modes.dark : Modes.light))}>
          <ThemeMenuComponent themeMode={props.themeMode} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => props.setOpenAboutDialog(true)}>
          <ListItemIcon css={listColor}>
            <InfoIcon />
          </ListItemIcon>
          {t("appBar.about")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon css={listColor}>
            <LogoutIcon />
          </ListItemIcon>
          {t("appBar.logout")}
        </MenuItem>
      </Menu>
    </Box>
  );
}
