export const translation = {
  translation: {
    index: {
      title: "Journal de tensiomètre",
    },
    appBar: {
      title: "Journal de tensiomètre",
      locale: "Langue",
      appearance: "Apparence",
      about: "À propos",
      logout: "Déconnexion",
    },
    about: {
      title: "À propos du journal de tensiomètre",
      body1: "Pour toutes questions, commentaires ou suggestions, contactez-moi à l'adresse suivante:",
      body2: "Prenez soin de vous!",
      ok: "Ok",
    },
    home: {
      add: "Ajouter une lecture",
      print: "Imprimer le journal",
      chart: "Graphique du journal",
      table: "Tableau du journal",
      info: "Information",
      howTo: {
        title1: "Bienvenu!",
        body1:
          "Vous n'avez pas encore ajouté de lecture de pression sanguine. Vous êtes libre de fermer cette fenêtre et de le faire maintenant. Sinon, n'hésitez pas à faire défiler ces diapositives afin d'avoir un aperçu des différentes fonctionnalités de l'application.",
        title2: "Ajouter des lectures",
        body2:
          "Cliquez ou appuyez sur le bouton Ajouter afin d'inscrire des lectures de pression sanguine et débuter l'utilisation de l'application. Vous pourrez modifier ou effacer les lectures par après si nécessaire.",
        title3: "Consulter le tableau des lectures",
        body3:
          "Cliquez ou appuyer sur le bouton Tableau afin de consulter votre historique de lecture de pression sanguine. Vous pouvez sélectionner une rangée du tableau pour modifier ou effacer une lecture. Vous pouvez utiliser le bouton de filtre en haut à droite pour n'afficher que les lectures à l'intérieur d'une plage de date donnée.",
        title4: "Consulter le graphique des lectures",
        body4:
          "Cliquez ou appuyez sur le bouton Graphique afin de visualiser votre historique de lecture de pression sanguine. Les données du graphique peuvent aussi être filtrées à l'aide du bouton en haut à droite.",
        title5: "En savoir plus à propos de l'hypertension",
        body5:
          "Cliquez ou appuyez sur le bouton Information Médicale afin d'en apprendre plus au sujet de la pression sanguine et l'hypertension. Vous trouverez aussi des références externes vers des ressources fiables et pertinentes en lien avec la haute pression et la santé cardiovasculaire.",
        ok: "J'ai compris!",
      },
    },
    filter: {
      title: "Filtre des données",
      dateLabel: "Filtrer par plage de dates",
      startDate: "Date de début",
      endDate: "Date de fin",
      datePickerLabel: "aaaa-mm-jj",
      ok: "Appliquer",
      cancel: "Retirer le filtre",
    },
    chart: {
      title: "Graphique des lectures",
      systolic: "Systolique (mmHg)",
      diastolic: "Diastolique (mmHg)",
    },
    table: {
      title: "Journal des lectures",
      date: "Date",
      time: "Heure",
      systolic: "Systolique (mmHg)",
      diastolic: "Diastolique (mmHg)",
      systolicMobile: "Sys. (mmHg)",
      diastolicMobile: "Dia. (mmHg)",
      pulse: "Pouls (bpm)",
      note: "Notes",
      average: "Moyenne:",
    },
    info: {
      title: "L'hypertension",
      header1: "Qu'est-ce que la pression sanguine?",
      body1_1:
        "La pression sanguine est la mesure de la force créée par la circulation sanguine contre les parois internes des artères de votre corps. Les lectures de pression sanguine consiste en deux mesures. Le premier nombre est la pression systolique, qui représente la pression lorsque le cœur se contracte et projette du sang dans tout votre système sanguin. Le second nombre est la pression diastolique, qui représente la pression dans le système sanguin lorsque le cœur est au repos entre les battements. ",
      header2: "Qu'est ce que l'hypertension?",
      body2_1:
        "L'hypertension est un problème de santé qui survient lorsque votre moyenne de pression sanguine au repos est supérieure à un certain seuil. Si vos lectures sont fréquemment supérieures à 140 pour la pression systolique et 90 pour la pression diastolique, ou 130 et 80 si vous souffrez du diabète ou d'une maladie des reins, vous êtes considérés comme ayant de l'hypertension. Votre pression sanguine optimale devrait être d'environ 120 et 80.",
      body2_2:
        "Comme l'hypertension ne cause pas de symptômes apparents avant de créer des problèmes de santés majeurs, il est crucial que vous surveillez votre pression sanguine de façon constante à la maison si vous suspectez de souffrir de cette condition, en utilisant un tensiomètre. Bien qu'elle affecte principalement les gens de 55 ans et plus, les jeunes adultes ne sont pas immunisés contre cette condition.",
      header3: "Quelles-sont les complications de l'hypertension?",
      body3_1:
        "Malgré que les symptômes apparents ne sont presque jamais sentis,  l'hypertension est une condition médicale sérieuse qui augmente significativement les risques de maladies cardiaques ainsi que les accidents vasculaires cérébraux (AVC). Une pression sanguine au delà du seuil de la pré hypertension de 130 et 85 est associée à un risque 2,5 fois plus élevé d'être affligé d'une maladie cardiovasculaire chez la femme et 1,6 fois plus élevé chez l'homme.",
      body3_2:
        "Selon Santé Canada, environ un adulte sur cinq vit présentement avec un diagnostic d'hypertension. La véritable prévalence de l'hypertension est malgré tout probablement sous-évaluée étant donné qu'approximativement 17% des individus ne savent pas que leur pression sanguine est trop élevée.",
      header4: "Comment réduire la pression sanguine?",
      body4_1:
        "L'hypertension est causée par plusieurs facteurs, dont certains sont hors de votre contrôle, incluant votre âge, votre orgine ethnique, vos prédispositions génétiques ainsi que votre sexe. Cependant, vos choix de style de vie, tels que l'alimentation, la sédentarité ainsi que l'abus de substances peuvent influencer votre pression sanguine.",
      body4_2:
        "Si vous déterminez que votre pression sanguine est au-delà du seuil de la pré-hypertension de 130 et 85, veuillez consulter un médecin afin d'établir un suivi pour votre condition. Bien que des médicaments peuvent être prescrits afin de contrôler l'hypertension, des changements dans votre style de vie peuvent aussi améliorer votre pression sanguine. Réduire la consommation de sodium, surtout des aliments transformés, réduire la consommation de gras, consommer plus de fruits et de légumes ainsi que d'être plus actif physiquement va grandement améliorer votre santé et contribuera à contrôler votre pression sanguine.",
      header5: "Ressources externes",
      body5_1: "Ressources sur l'hypertension du Gouvernement du Canada",
      link1:
        "https://www.canada.ca/fr/sante-publique/services/maladies-chroniques/maladie-cardiovasculaire/hypertension.html",
      body5_2: "Hypertension Canada",
      link2: "https://hypertension.ca/fr/",
      body5_3: "Appareils de mesure de la pression artérielle recommandés par Hypertension Canada",
      link3: "https://hypertension.ca/fr/measuring-blood-pressure/blood-pressure-devices/",
      body5_4: "Cœur et AVC Canada",
      link4: "https://www.coeuretavc.ca/",
      body5_5: "Institut de Cardiologie de Montréal",
      link5: "https://www.icm-mhi.org/fr",
    },
    readingForm: {
      createTitle: "Nouvelle lecture",
      updateTitle: "Modifier lecture",
      date: "Date",
      time: "Heure",
      systolic: "Systolique (mmHg)",
      diastolic: "Diastolique (mmHg)",
      pulse: "Pouls (bpm)",
      note: "Notes",
      createOk: "Ajouter",
      editOk: "Sauvegarder",
      cancel: "Annuler",
      delete: "Supprimer",
      confirmationMessage: "Are you sure you wish to delete the selected reading?",
    },
    confirmationDialog: {
      title: "Avertissement",
      ok: "Oui",
      cancel: "Non",
    },
    errorDialog: {
      title: "Erreur",
      ok: "Ok",
    },
    pwaUpdate: {
      message: "Une nouvelle vesrion de l'application est disponnible!",
      reload: "Redémarrer",
    },
  },
};
