/** @jsxImportSource @emotion/react */

import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ContainerToolBarComponent from "../home/content-container/ContainerToolBar";
import { css } from "@emotion/react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const styles = css({
  display: "grid",
  alignItems: "center",
  maxHeight: "calc(100% - 64px)",
  overflow: "auto",
  textAlign: "start",
  marginTop: "auto",
  marginBottom: "auto",
  paddingTop: "5%",
  paddingBottom: "5%",
});

const sxTitle = { typography: { xs: "h5", sm: "h3" } };

function InfoComponent() {
  const { t } = useTranslation();

  return (
    <Fragment>
      <ContainerToolBarComponent
        label={t("info.title")}
        filter={false}
      />
      <Box
        css={styles}
        sx={{ paddingX: { xs: "5%", sm: "20%" } }}
      >
        <Typography sx={sxTitle}>{t("info.header1")}</Typography>
        <br />
        <Typography
          variant="body1"
          component="p"
        >
          {t("info.body1_1")}
        </Typography>
        <br /> <br />
        {/* ------------------ */}
        <Typography sx={sxTitle}>{t("info.header2")}</Typography>
        <br />
        <Typography
          variant="body1"
          component="p"
        >
          {t("info.body2_1")}
        </Typography>
        <br />
        <Typography
          variant="body1"
          component="p"
        >
          {t("info.body2_2")}
        </Typography>
        <br /> <br />
        {/* ------------------ */}
        <Typography sx={sxTitle}>{t("info.header3")}</Typography>
        <br />
        <Typography
          variant="body1"
          component="p"
        >
          {t("info.body3_1")}
        </Typography>
        <br />
        <Typography
          variant="body1"
          component="p"
        >
          {t("info.body3_2")}
        </Typography>
        <br /> <br />
        {/* ------------------ */}
        <Typography sx={sxTitle}>{t("info.header4")}</Typography>
        <br />
        <Typography
          variant="body1"
          component="p"
        >
          {t("info.body4_1")}
        </Typography>
        <br />
        <Typography
          variant="body1"
          component="p"
        >
          {t("info.body4_2")}
        </Typography>
        <br /> <br />
        {/* ------------------ */}
        <Typography sx={sxTitle}>{t("info.header5")}</Typography>
        <br />
        <ExternalLink
          label={t("info.body5_1")}
          link={t("info.link1")}
        />
        <br />
        <ExternalLink
          label={t("info.body5_2")}
          link={t("info.link2")}
        />
        <br />
        <ExternalLink
          label={t("info.body5_3")}
          link={t("info.link3")}
        />
        <br />
        <ExternalLink
          label={t("info.body5_4")}
          link={t("info.link4")}
        />
        <br />
        <ExternalLink
          label={t("info.body5_5")}
          link={t("info.link5")}
        />
        <br /> <br />
        {/* ------------------ */}
      </Box>
    </Fragment>
  );
}

export default InfoComponent;

interface ExternalLinkProps {
  label: string;
  link: string;
}

function ExternalLink(props: ExternalLinkProps) {
  return (
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box
        sx={{
          fontWeight: 700,
          color: "text.primary",
          textDecoration: "underline",
          transition: "0.5s",
          ":hover": { color: "text.secondary" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {props.label}
          <OpenInNewIcon sx={{ display: { xs: "none", sm: "flex" }, marginLeft: "5px" }} />
        </Box>
      </Box>
    </a>
  );
}
