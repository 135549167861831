/** @jsxImportSource @emotion/react */

import { DialogActions, Fade, IconButton, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Normalize, useTranslation } from "react-i18next";
import { dialogTitle } from "../../styles/app-styles";
import DialogTransition from "../dialogs/transition/DialogTransition";
import { translation } from "../../translations/en/translation";
import { SvgIconComponent } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import HowToSlideComponent from "./HowToSlide";
import StepTransitionComponent from "./StepTransition";
import HowToStepperComponent from "./HowToStepper";

export type PulsingButtons = "add" | "table" | "chart" | "info" | "print";
const defaultPuslingButton: PulsingButtons = "add";

interface SlideInfo {
  pulsingButton?: PulsingButtons | undefined;
  titleKey: Normalize<typeof translation.translation>;
  bodyKey: Normalize<typeof translation.translation>;
  icon?: SvgIconComponent;
  logo?: boolean;
}

const slidesInfo: SlideInfo[] = [
  {
    titleKey: "home.howTo.title1",
    bodyKey: "home.howTo.body1",
    logo: true,
  },
  {
    pulsingButton: "add",
    titleKey: "home.howTo.title2",
    bodyKey: "home.howTo.body2",
    icon: AddIcon,
  },
  {
    pulsingButton: "table",
    titleKey: "home.howTo.title3",
    bodyKey: "home.howTo.body3",
    icon: TableChartIcon,
  },
  {
    pulsingButton: "chart",
    titleKey: "home.howTo.title4",
    bodyKey: "home.howTo.body4",
    icon: SsidChartIcon,
  },
  {
    pulsingButton: "info",
    titleKey: "home.howTo.title5",
    bodyKey: "home.howTo.body5",
    icon: MedicalServicesIcon,
  },
];

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setPulsingButton: Dispatch<SetStateAction<PulsingButtons | undefined>>;
}

export function HowToDialogComponent(props: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(0);
  const { setPulsingButton } = props;

  useEffect(() => {
    const pulsingButton = slidesInfo[activeStep].pulsingButton ?? defaultPuslingButton;
    setPulsingButton(pulsingButton);
  }, [activeStep, setPulsingButton]);

  const handleClose = () => {
    props.setOpen(false);
    setPulsingButton(defaultPuslingButton);
  };

  const handleStepChange = (next: boolean) => {
    const step = next ? +1 : -1;

    setPreviousStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + step);
  };

  return (
    <Dialog
      open={props.open}
      TransitionComponent={DialogTransition}
      onClose={handleClose}
      PaperProps={{
        sx: {
          height: { xs: "75vh", sm: "350px" },
          width: { xs: "90vw", sm: "600px" },
          borderTopRightRadius: "20px",
          overflow: "hidden",
        },
      }}
    >
      <Fade
        in={props.open}
        timeout={1500}
      >
        <DialogTitle css={dialogTitle}>
          {slidesInfo.map((slideInfo, index) => (
            <StepTransitionComponent
              key={`title${index}`}
              index={index}
              activeStep={activeStep}
              previousStep={previousStep}
            >
              <Typography
                variant="h5"
                sx={{ maxWidth: "95%" }}
              >
                {t(slideInfo.titleKey) as string}
              </Typography>
            </StepTransitionComponent>
          ))}
          <IconButton
            color="primary"
            size="small"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              backgroundColor: "secondary.main",
              borderColor: "primary.main",
              border: "1px solid",
              "&:hover": {
                backgroundColor: "secondary.light",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Fade>
      <Fade
        in={props.open}
        timeout={1500}
      >
        <DialogContent sx={{ textAlign: "center", overflow: "hidden" }}>
          {slidesInfo.map((slideInfo, index) => (
            <StepTransitionComponent
              key={`slide${index}`}
              index={index}
              activeStep={activeStep}
              previousStep={previousStep}
            >
              <HowToSlideComponent
                body={t(slideInfo.bodyKey) as string}
                icon={slideInfo.icon}
                logo={slideInfo.logo}
                themeMode={theme.palette.mode}
              />
            </StepTransitionComponent>
          ))}
        </DialogContent>
      </Fade>
      <Fade
        in={props.open}
        timeout={1500}
      >
        <DialogActions sx={{ justifyContent: "center" }}>
          <HowToStepperComponent
            activeStep={activeStep}
            handleStepChange={handleStepChange}
            slideAmount={slidesInfo.length}
          />
        </DialogActions>
      </Fade>
    </Dialog>
  );
}

export default HowToDialogComponent;
