/** @jsxImportSource @emotion/react */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dialogTitle } from "../../styles/app-styles";
import DialogTransition from "./transition/DialogTransition";

export class ConfirmationDialog {
  setConfirmation: Dispatch<SetStateAction<boolean>>;
  message: string;

  constructor(setConfirmation: Dispatch<SetStateAction<boolean>>, message: string) {
    this.setConfirmation = setConfirmation;
    this.message = message;
  }
}

interface Props {
  confirmationDialog: ConfirmationDialog | undefined;
}

export function ConfirmationDialogComponent(props: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.confirmationDialog) {
      setOpen(true);
    }
  }, [props.confirmationDialog]);

  function handleConfirmation(confirmation: boolean) {
    props.confirmationDialog?.setConfirmation(confirmation);
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      onClose={() => handleConfirmation(true)}
    >
      <DialogTitle css={dialogTitle}>{t("confirmationDialog.title")}</DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogContentText>{props.confirmationDialog?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => handleConfirmation(true)}
        >
          {t("confirmationDialog.ok")}
        </Button>
        <Button
          color="secondary"
          onClick={() => handleConfirmation(false)}
        >
          {t("confirmationDialog.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialogComponent;
