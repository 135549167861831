/** @jsxImportSource @emotion/react */

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PrintIcon from "@mui/icons-material/Print";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import { useTranslation } from "react-i18next";
import { Divider, Drawer, Slide } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SideBarButton from "./SideBarButton";
import { useState } from "react";
import { actionNav, ActiveNav } from "../../home/Home";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { getStorageUserSetting, setStorageUserSetting } from "../../../services/settings";
import { css, Theme } from "@emotion/react";
import { PulsingButtons } from "../../how-to-dialog/HowToDialog";

const drawerWidth = 300;
const closedDrawerWidth = 91;

const drawerPaper = (theme: Theme) =>
  css({
    "& .MuiDrawer-paper": {
      top: 64,
      backgroundColor: theme.palette.primary.main,
      zIndex: 10,
      overflowX: "hidden",
    },
  });

const drawerOpenned = (theme: Theme) => {
  const cssObject = {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      // duration: theme.transitions.duration.enteringScreen,
      duration: theme.transitions.duration.complex,
    }),
  };

  return css({
    ...cssObject,
    "& .MuiDrawer-paper": { ...cssObject },
  });
};

const drawerClosed = (theme: Theme) => {
  const cssObject = {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      // duration: theme.transitions.duration.leavingScreen,
      duration: theme.transitions.duration.complex,
    }),
    width: closedDrawerWidth,
  };

  return css({
    ...cssObject,
    "& .MuiDrawer-paper": { ...cssObject },
  });
};

const drawerHeader = (theme: Theme) =>
  css({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "64px",
  });

const drawer = () =>
  css({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    overflowX: "hidden",
  });

interface Props {
  children: React.ReactNode;
  activeNav: ActiveNav;
  handleNav: (nav: ActiveNav) => void;
  addReading: () => void;
  pulsingButton: PulsingButtons | undefined;
}

function SideBarComponent(props: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(getStorageUserSetting<boolean>("sideBarOpen"));

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        id="print-none"
        variant="permanent"
        open={open}
        css={[drawer, drawerPaper, open ? drawerOpenned : drawerClosed]}
      >
        <Box
          css={drawerHeader}
          style={{ justifyContent: open ? "flex-end" : "center" }}
        >
          <IconButton
            sx={{ color: "primary.contrastText" }}
            onClick={() => {
              setOpen(!open);
              setStorageUserSetting<boolean>("sideBarOpen", !open);
            }}
          >
            {open ? <ChevronLeftIcon fontSize="large" /> : <ChevronRightIcon fontSize="large" />}
          </IconButton>
        </Box>
        <Divider />
        {/* Table */}
        <SideBarButton
          label={t("home.table")}
          icon={TableChartIcon}
          open={open}
          actionType="route"
          handleNav={props.handleNav}
          activeNav={props.activeNav}
          route="table"
          pulsing={props.pulsingButton === "table"}
        />
        {/* Chart */}
        <SideBarButton
          label={t("home.chart")}
          icon={SsidChartIcon}
          open={open}
          actionType="route"
          handleNav={props.handleNav}
          activeNav={props.activeNav}
          route="chart"
          pulsing={props.pulsingButton === "chart"}
        />
        {/* Info */}
        <SideBarButton
          label={t("home.info")}
          icon={MedicalServicesIcon}
          open={open}
          actionType="route"
          handleNav={props.handleNav}
          activeNav={props.activeNav}
          route="info"
          pulsing={props.pulsingButton === "info"}
        />
        <Divider />
        <Slide
          direction="right"
          in={actionNav.includes(props.activeNav)}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* Add Reading */}
            <SideBarButton
              id="add-button"
              label={t("home.add")}
              icon={AddIcon}
              open={open}
              actionType="function"
              function={props.addReading}
              pulsing={props.pulsingButton === "add"}
            />
            {/* Print */}
            <SideBarButton
              label={t("home.print")}
              icon={PrintIcon}
              open={open}
              actionType="function"
              function={() => window.print()}
            />
            <Divider />
          </Box>
        </Slide>
      </Drawer>
      {props.children}
    </Box>
  );
}

export default SideBarComponent;
