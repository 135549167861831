/** @jsxImportSource @emotion/react */

import { Box, Slide } from "@mui/material";
import { css } from "@emotion/react";
import { ReactNode, useEffect, useState } from "react";

const transitionBox = () =>
  css({
    height: "100%",
    width: "100%",
    overflow: "hidden",
  });

interface Props {
  children: ReactNode;
  index: number;
  activeStep: number;
  previousStep: number;
}

function StepTransitionComponent(props: Props) {
  const [slideIn, setSlideIn] = useState<boolean>(true);
  const [slideOut, setSlideOut] = useState<boolean>(false);
  const { index, activeStep, previousStep } = props;

  const delay = 250;
  const duration = 200;

  useEffect(() => {
    if (index === activeStep) {
      setTimeout(() => {
        setSlideIn(true);
        setSlideOut(false);
      }, delay);
    }
    if (index !== activeStep) {
      setSlideOut(true);
      setTimeout(() => {
        setSlideIn(false);
      }, delay);
    }
  }, [index, activeStep, slideIn, slideOut]);

  return (
    <Slide
      in={slideIn}
      direction={index > previousStep ? "left" : "right"}
      timeout={{ enter: duration, exit: 0 }}
      mountOnEnter
      unmountOnExit
    >
      <Box css={transitionBox}>
        <Slide
          in={!slideOut}
          direction={index > activeStep ? "left" : "right"}
          timeout={{ enter: 0, exit: duration }}
          mountOnEnter
          unmountOnExit
        >
          <Box css={transitionBox}>{props.children}</Box>
        </Slide>
      </Box>
    </Slide>
  );
}

export default StepTransitionComponent;
