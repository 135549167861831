/** @jsxImportSource @emotion/react */

import { Box, ListItemIcon, Typography } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Modes, ThemeMode } from "../../../styles/themes";
import { useTranslation } from "react-i18next";
import { listColor } from "../styles/styles";

interface Props {
  themeMode: ThemeMode;
}

function ThemeMenuComponent(props: Props) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      <ListItemIcon css={listColor}>
        {props.themeMode === Modes.dark ? <Brightness7Icon /> : <Brightness4Icon />}
      </ListItemIcon>
      <Typography>{t("appBar.appearance")}</Typography>
    </Box>
  );
}

export default ThemeMenuComponent;
