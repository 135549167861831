import { Button, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as serviceWorker from "../../serviceWorkerRegistration";

function PwaUpdateComponent() {
  const { t } = useTranslation();
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  const onSWUpdate = useCallback(
    (registration: ServiceWorkerRegistration) => {
      setShowReload(true);
      setWaitingWorker(registration.waiting);
    },
    [setWaitingWorker]
  );

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, [onSWUpdate]);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  return (
    <Snackbar
      open={showReload}
      message={t("pwaUpdate.message")}
      onClick={reloadPage}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      action={
        <Button
          variant="outlined"
          size="small"
          onClick={reloadPage}
        >
          {t("pwaUpdate.reload")}
        </Button>
      }
    />
  );
}

export default PwaUpdateComponent;
