import { Button, MobileStepper } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

interface Props {
  activeStep: number;
  handleStepChange: (next: boolean) => void;
  slideAmount: number;
}

function HowToStepperComponent(props: Props) {
  return (
    <MobileStepper
      variant="dots"
      steps={props.slideAmount}
      position="static"
      activeStep={props.activeStep}
      sx={{
        flexGrow: 0.75,
        background: "inherit",
        "& .MuiMobileStepper-dots": { flexGrow: 0.5, justifyContent: "space-around" },
        "& .MuiMobileStepper-dot": {
          width: 12,
          height: 12,
          backgroundColor: "secondary.main",
          boxShadow: 2,
          opacity: 0.7,
          transition: "1000ms",
        },
        "& .MuiMobileStepper-dotActive": {
          boxShadow: 5,
          opacity: 1,
          transition: "1000ms",
        },
      }}
      backButton={
        <Button
          color="secondary"
          onClick={() => props.handleStepChange(false)}
          disabled={props.activeStep === 0}
        >
          <KeyboardArrowLeft />
        </Button>
      }
      nextButton={
        <Button
          color="secondary"
          onClick={() => props.handleStepChange(true)}
          disabled={props.activeStep === props.slideAmount - 1}
        >
          <KeyboardArrowRight />
        </Button>
      }
    />
  );
}

export default HowToStepperComponent;
