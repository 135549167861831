import { inputHandler, InputProps } from "../../utils/input-handler";
import { TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { useState } from "react";
import { formatTime, parseTimeString, timeStringFormat } from "../../utils/date-time-helpers";

interface Props<T, V extends string> extends InputProps<T, V> {
  isDisabled?: boolean;
  timeFormat?: string;
  label?: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
}

function TimePickerComponent<T, V extends string>(props: Props<T, V>) {
  const { isDisabled = false, isRequired = false, isFullWidth = true, timeFormat = timeStringFormat } = props;
  const [value, setValue] = useState<Date | null>(parseTimeString(props.obj[props.objKey] as V));

  function handleTimeChange(newTime: Date | null) {
    if (newTime) {
      setValue(newTime);
      if (!isNaN(newTime.getTime())) {
        const timeString = formatTime(newTime, timeFormat);
        inputHandler(props.obj, props.objKey, props.setObj, timeString as V);
      }
    }
  }

  return (
    <TimePicker
      label={props.label}
      inputFormat={timeFormat}
      disabled={isDisabled}
      ampm={false}
      value={value}
      onChange={(newValue) => handleTimeChange(newValue)}
      renderInput={(params) => (
        <TextField
          color="secondary"
          variant="standard"
          required={isRequired}
          fullWidth={isFullWidth}
          {...params}
        />
      )}
    />
  );
}

export default TimePickerComponent;
