import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { inputHandler, InputProps, KeyOfType } from "../../utils/input-handler";

interface Props<T, V extends boolean> extends InputProps<T, V> {
  label?: string;
}

function SwitchComponent<T, V extends boolean>(props: Props<T, V>) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={props.obj[props.objKey] as V}
            onChange={() =>
              inputHandler(
                props.obj,
                props.objKey as unknown as KeyOfType<T, boolean>,
                props.setObj,
                !props.obj[props.objKey]
              )
            }
          />
        }
        label={props.label}
      />
    </FormGroup>
  );
}

export default SwitchComponent;
