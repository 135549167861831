import { Fragment, ReactNode, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../../services/auth";

interface Props {
  children: ReactNode;
}

const authService = new AuthService();

function AuthGuardComponent(props: Props) {
  const [isAuthenticated] = useState(authService.isUserSignedIn());

  return (
    <Fragment>
      {isAuthenticated ? (
        props.children
      ) : (
        <Navigate
          replace
          to="/login"
        />
      )}
    </Fragment>
  );
}

export default AuthGuardComponent;
