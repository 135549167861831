import { Dispatch, SetStateAction } from "react";

export interface InputProps<T, V> {
  obj: T;
  objKey: KeyOfType<T, V>;
  setObj: Dispatch<SetStateAction<T>>;
}

// type PropTypeOf<T, V> = T[KeyOfType<T, V>];

export type KeyOfType<T, V> = { [K in keyof T]: T[K] extends V ? K : never }[keyof T];

export function inputHandler<T, V>(obj: T, key: KeyOfType<T, V>, setObj: Dispatch<SetStateAction<T>>, value: V) {
  setObj({ ...obj, [key]: value });
}
