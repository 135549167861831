/** @jsxImportSource @emotion/react */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Fragment, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { useTranslation } from "react-i18next";
import { TableFilter } from "../../../data-models/table-filter";
import { useAppDispatch, useAppSelector } from "../../../stores/app/hooks";
import { filterReadings } from "../../../services/readings-filter";
import DateFilterComponent from "./DateFilter";
import { getStorageUserSetting, setStorageUserSetting } from "../../../services/settings";
import { setFilteredReadings } from "../../../stores/readings";
import { dialogTitle } from "../../../styles/app-styles";

function ToolBarFilterComponent() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { readings } = useAppSelector((state) => state.readings);
  const [open, setOpen] = useState(false);
  const [tableFilter, setTableFilter] = useState<TableFilter>(getStorageUserSetting<TableFilter>("tableFilter"));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setStorageUserSetting<TableFilter>("tableFilter", tableFilter);
    filterReadings(readings, tableFilter);
    dispatch(setFilteredReadings(filterReadings(readings, tableFilter)));
  }, [tableFilter, readings, dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (isActive: boolean) => {
    setTableFilter({ ...tableFilter, isActive });
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton
        color={theme.palette.mode === "light" ? "primary" : "secondary"}
        onClick={handleClickOpen}
      >
        {tableFilter.isActive ? <FilterListOffIcon /> : <FilterListIcon />}
      </IconButton>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => handleClose(false)}
      >
        <DialogTitle css={dialogTitle}>{t("filter.title")}</DialogTitle>
        {tableFilter && (
          <DialogContent dividers>
            <DateFilterComponent
              tableFilter={tableFilter}
              setTableFilter={setTableFilter}
            />
          </DialogContent>
        )}

        <DialogActions>
          <Button
            color="secondary"
            autoFocus
            onClick={() => handleClose(true)}
          >
            {t("filter.ok")}
          </Button>
          <Button
            color="secondary"
            onClick={() => handleClose(false)}
          >
            {t("filter.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default ToolBarFilterComponent;
