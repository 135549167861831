import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Reading } from "../data-models/reading";
import { AxiosError } from "axios";
import { FetchStatus, ThunkApi } from "./app/store";
import ApiService from "../services/api";
import { CustomError } from "../data-models/custom-error";

const urlPath = "/readings";
const apiService = new ApiService();

export const getReadings = createAsyncThunk<Reading[], undefined, ThunkApi>(
  "readings/getReadings",
  async (_voidArgs, thunkApi) => {
    try {
      const result = await apiService.getAsync<Reading>(urlPath);
      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(handleError(error));
    }
  },
  {
    condition: (_voidArgs, { getState }) => {
      const { readings } = getState();
      const fetchStatus = readings.fetchStatus;
      if (fetchStatus === "fulfilled" || fetchStatus === "pending") {
        return false;
      }
      return true;
    },
  }
);

export const createReading = createAsyncThunk<Reading, Reading, ThunkApi>(
  "readings/createReading",
  async (reading, thunkApi) => {
    try {
      const result = await apiService.postAsync<Reading>(urlPath, reading);
      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(handleError(error));
    }
  }
);

export const updateReading = createAsyncThunk<Reading, Reading, ThunkApi>(
  "readings/updateReading",
  async (reading, thunkApi) => {
    try {
      const result = await apiService.putAsync<Reading>(urlPath, reading.id, reading);
      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(handleError(error));
    }
  }
);

export const deleteReading = createAsyncThunk<Reading, string, ThunkApi>(
  "readings/deleteReading",
  async (id, thunkApi) => {
    try {
      const result = await apiService.deleteAsync<Reading>(urlPath, id);
      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(handleError(error));
    }
  }
);

interface ReadingSlice {
  readings: Reading[];
  filteredReadings: Reading[];
  fetchStatus: FetchStatus;
  error: CustomError | undefined;
}

const initialState: ReadingSlice = {
  readings: [],
  filteredReadings: [],
  fetchStatus: "idle",
  error: undefined,
};

export const readingsSlice = createSlice({
  name: "readings",
  initialState,
  reducers: {
    setFilteredReadings: (state, action: PayloadAction<Reading[]>) => {
      state.filteredReadings = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Readings
    builder.addCase(getReadings.pending, (state, action) => {
      state.fetchStatus = action.meta.requestStatus;
    });
    builder.addCase(getReadings.fulfilled, (state, action) => {
      state.fetchStatus = action.payload.length > 0 ? action.meta.requestStatus : "noContent";
      state.readings = action.payload;
    });
    builder.addCase(getReadings.rejected, (state, action) => {
      state.fetchStatus = action.meta.requestStatus;
      if (action.payload) {
        state.error = JSON.parse(action.payload);
      }
    });
    // Create Readings
    builder.addCase(createReading.fulfilled, (state, action) => {
      state.readings.push(action.payload);
      if (state.fetchStatus === "noContent") {
        state.fetchStatus = "fulfilled";
      }
    });
    builder.addCase(createReading.rejected, (state, action) => {
      if (action.payload) {
        state.error = JSON.parse(action.payload);
      }
    });
    // Update Readings
    builder.addCase(updateReading.fulfilled, (state, action) => {
      state.readings = state.readings.map((reading) => {
        if (reading.id === action.payload.id) {
          return action.payload;
        }
        return reading;
      });
    });
    builder.addCase(updateReading.rejected, (state, action) => {
      if (action.payload) {
        state.error = JSON.parse(action.payload);
      }
    });
    // Delete Readings
    builder.addCase(deleteReading.fulfilled, (state, action) => {
      state.readings = state.readings.filter((reading) => reading.id !== action.payload.id);
      if (state.readings.length === 0) {
        state.fetchStatus = "noContent";
      }
    });
    builder.addCase(deleteReading.rejected, (state, action) => {
      if (action.payload) {
        state.error = JSON.parse(action.payload);
      }
    });
  },
});

export const { setFilteredReadings } = readingsSlice.actions;

export default readingsSlice.reducer;

function handleError(error: AxiosError | unknown): string {
  if (error instanceof AxiosError) {
    const errorObj = {
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      message: error?.response?.data,
    };
    return JSON.stringify(errorObj);
  } else {
    const errorObj = { status: undefined, statusText: undefined, message: "Error" };
    return JSON.stringify(errorObj);
  }
}
