/** @jsxImportSource @emotion/react */

import { Container, Paper } from "@mui/material";
import { css } from "@emotion/react";
import { Fragment, ReactNode } from "react";
import { useAppSelector } from "../../../stores/app/hooks";
import LogoPageComponent from "../../logo-page/LogoPage";

const paper = () =>
  css({
    width: "100%",
    height: "100%",
  });

interface Props {
  children: ReactNode;
  contentCheck: boolean;
}

function ContainerWrapperComponent(props: Props) {
  const { fetchStatus } = useAppSelector((state) => state.readings);

  return (
    <Fragment>
      {fetchStatus === "pending" && <LogoPageComponent loading={true} />}
      {fetchStatus === "rejected" && <LogoPageComponent />}
      {fetchStatus === "fulfilled" && <ComponentContainer>{props.children}</ComponentContainer>}
      {fetchStatus === "noContent" && props.contentCheck && <LogoPageComponent />}
      {fetchStatus === "noContent" && !props.contentCheck && <ComponentContainer>{props.children}</ComponentContainer>}
    </Fragment>
  );
}

export default ContainerWrapperComponent;

function ComponentContainer(props: { children: ReactNode }) {
  return (
    <Container
      maxWidth="lg"
      sx={{ margin: "auto", paddingTop: "5%", paddingBottom: { xs: "calc(5% + 56px)", sm: "5%" } }}
      id="scrollable-print"
    >
      <Paper
        elevation={10}
        css={paper}
      >
        {props.children}
      </Paper>
    </Container>
  );
}
