import { ActiveNav, Navs } from "../components/home/Home";
import { TableFilter, TableFilterInit } from "../data-models/table-filter";
import { Modes, ThemeMode } from "../styles/themes";
import { KeyOfType } from "../utils/input-handler";

const settingsKey = "BPMUserSettings";
interface UserSettings {
  themeMode: ThemeMode;
  activeNav: ActiveNav;
  sideBarOpen: boolean;
  tableFilter: TableFilter;
  // When adding props here, update the validateUserSettings function with
  // the new prop validation
}

class UserSettingsInit implements UserSettings {
  themeMode: ThemeMode;
  activeNav: ActiveNav;
  sideBarOpen: boolean;
  tableFilter: TableFilter;

  constructor() {
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      this.themeMode = Modes.dark;
    } else {
      this.themeMode = Modes.light;
    }
    this.activeNav = Navs.table;
    this.sideBarOpen = true;
    this.tableFilter = new TableFilterInit();
  }
}

let _userSettings: UserSettings | null = null;

export function getStorageUserSetting<V>(key: KeyOfType<UserSettings, V>): UserSettings[KeyOfType<UserSettings, V>] {
  let userSettings = getUserSettings();
  const value = userSettings[key];
  if (value === null) {
    const initValue = new UserSettingsInit()[key];
    userSettings = { ...userSettings, ...{ [key]: initValue } };
    set(settingsKey, JSON.stringify(userSettings));
  }

  return value;
}

export function setStorageUserSetting<V>(
  key: KeyOfType<UserSettings, V>,
  value: UserSettings[KeyOfType<UserSettings, V>]
) {
  let userSettings = getUserSettings();
  userSettings = { ...userSettings, ...{ [key]: value } };
  _userSettings = userSettings;
  set(settingsKey, JSON.stringify(userSettings));
}

function getUserSettings(): UserSettings {
  if (!_userSettings) {
    const userSettings = getStorageUserSettings();
    _userSettings = userSettings;
  }

  return _userSettings;
}

function getStorageUserSettings(): UserSettings {
  const userSettingsInit = new UserSettingsInit();
  let userSettings: UserSettings;
  let storageUserSettings = get(settingsKey);

  if (!storageUserSettings) {
    set(settingsKey, JSON.stringify(userSettingsInit));
    storageUserSettings = get(settingsKey);
  }
  if (storageUserSettings) {
    try {
      userSettings = JSON.parse(storageUserSettings) as UserSettings;
      userSettings = validateUserSettings(userSettings, userSettingsInit);
    } catch (error) {
      return userSettingsInit;
    }

    return userSettings;
  }
  // default return if all else fails somehow
  return userSettingsInit;
}

function validateUserSettings(userSettings: UserSettings, userSettingsInit: UserSettings): UserSettings {
  userSettings.sideBarOpen = validateBooleanSetting("sideBarOpen", userSettings.sideBarOpen, userSettingsInit);
  userSettings.themeMode = validateEnumSetting<ThemeMode>("themeMode", userSettings.themeMode, Modes, userSettingsInit);
  userSettings.activeNav = validateEnumSetting<ActiveNav>("activeNav", userSettings.activeNav, Navs, userSettingsInit);
  userSettings.tableFilter = validateObjSetting<TableFilter>("tableFilter", userSettings.tableFilter, userSettingsInit);

  return userSettings;
}

function validateObjSetting<T extends UserSettings[KeyOfType<UserSettings, T>]>(
  key: KeyOfType<UserSettings, T>,
  obj: UserSettings[KeyOfType<UserSettings, T>],
  userSettingsInit: UserSettings
): T {
  // Make sure settings Obj remain flat

  const initObj = userSettingsInit[key];

  for (const initKey in initObj) {
    // add missing props
    if (obj[initKey] === null) {
      obj[initKey] = initObj[initKey];
    }
    // type check primitive types

    const primitiveTypes = ["boolean", "number", "string"];

    primitiveTypes.forEach((type) => {
      if (typeof initObj[initKey] === type && typeof obj[initKey] !== type) {
        obj[initKey] = initObj[initKey];
      }
    });
  }

  return obj as T;
}

function validateBooleanSetting(
  key: KeyOfType<UserSettings, boolean>,
  value: UserSettings[KeyOfType<UserSettings, boolean>],
  userSettingsInit: UserSettings
): boolean {
  if (typeof value !== "boolean") {
    value = userSettingsInit[key];
  }
  return value;
}

function validateEnumSetting<T>(
  key: KeyOfType<UserSettings, T>,
  value: UserSettings[KeyOfType<UserSettings, T>],
  enumObj: Record<string, T>,
  userSettingsInit: UserSettings
): T {
  if (!Object.values(enumObj).find((v) => v === value)) {
    value = userSettingsInit[key];
  }
  return value as T;
}

const get = (key: string) => {
  return localStorage.getItem(key);
};

const set = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};
