/** @jsxImportSource @emotion/react */

import SsidChartIcon from "@mui/icons-material/SsidChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { AppBar, Divider, Slide, Toolbar } from "@mui/material";
import { actionNav, ActiveNav } from "../../home/Home";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/system/Unstable_Grid";
import BottomNavigationButtonComponent from "./BottomNavigationButton";
import { css, Theme } from "@emotion/react";
import { PulsingButtons } from "../../how-to-dialog/HowToDialog";

const navShort = (theme: Theme) =>
  css({
    transition: `all ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}`,
  });

const navWide = (theme: Theme) =>
  css({
    transition: `all ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut}`,
  });

interface Props {
  children: ReactNode;
  activeNav: ActiveNav;
  handleNav: (nav: ActiveNav) => void;
  addReading: () => void;
  pulsingButton: PulsingButtons | undefined;
}

function BottomNavigationComponent(props: Props) {
  const [actionButtons, setActionButtons] = useState(actionNav.includes(props.activeNav));
  const [largeNav, setLargeNav] = useState(!actionNav.includes(props.activeNav));
  const [navJustifyContent, setNavJustifyContent] = useState<"start" | "space-around">("space-around");
  const navButtonProps = { activeNav: props.activeNav, handleNav: props.handleNav };
  const inDelay = 575;

  useEffect(() => {
    if (actionNav.includes(props.activeNav)) {
      setTimeout(() => {
        setLargeNav(false);
        setNavJustifyContent("start");
      }, 300);

      setTimeout(() => {
        setNavJustifyContent("space-around");
        setActionButtons(true);
      }, inDelay);
    } else {
      setActionButtons(false);
    }
  }, [props.activeNav]);

  return (
    <Fragment>
      {props.children}
      <AppBar
        id="print-none"
        elevation={3}
        position="fixed"
        sx={{ top: "auto", bottom: 0, left: 0, right: 0, height: "56px" }}
      >
        <Toolbar>
          <Grid
            container
            justifyContent={navJustifyContent}
            alignItems="center"
            sx={{ width: "100%", height: "56px" }}
          >
            <Grid
              container
              xs={largeNav ? 12 : 9}
              justifyContent="space-around"
              css={largeNav ? navShort : navWide}
            >
              <BottomNavigationButtonComponent
                nav="table"
                icon={TableChartIcon}
                {...navButtonProps}
                pulsing={props.pulsingButton === "table"}
              />
              <BottomNavigationButtonComponent
                nav="chart"
                icon={SsidChartIcon}
                {...navButtonProps}
                pulsing={props.pulsingButton === "chart"}
              />
              <BottomNavigationButtonComponent
                nav="info"
                icon={MedicalServicesIcon}
                {...navButtonProps}
                pulsing={props.pulsingButton === "info"}
              />
            </Grid>
            <Slide
              direction="left"
              in={actionButtons}
              onExited={() => setLargeNav(true)}
              mountOnEnter
              unmountOnExit
            >
              <Grid
                container
                sx={{ display: "flex", height: "56px", alignItems: "center" }}
                xs={3}
                justifyContent="space-around"
              >
                <Divider
                  orientation="vertical"
                  flexItem
                />
                <BottomNavigationButtonComponent
                  handleAction={props.addReading}
                  pulsing={props.pulsingButton === "add"}
                  icon={AddIcon}
                />
              </Grid>
            </Slide>
          </Grid>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}

export default BottomNavigationComponent;
