/** @jsxImportSource @emotion/react */

import { SvgIconComponent } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { ThemeMode } from "../../styles/themes";
import { pulsingButton } from "../action-menus/styles/styles";
import LogoIconComponent from "../logo-page/LogoIcon";

interface Props {
  body: string;
  icon?: SvgIconComponent;
  logo?: boolean;
  themeMode?: ThemeMode;
}

function HowToSlideComponent(props: Props) {
  const { icon: Icon, logo } = props;

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Grid
        xs={12}
        sm={2}
      >
        {Icon && (
          <Button
            color="secondary"
            variant="contained"
            sx={{ height: 64, width: 64 }}
            css={pulsingButton}
          >
            <Icon fontSize="large" />
          </Button>
        )}
        {logo && props.themeMode && (
          <LogoIconComponent
            sx={{ width: 100, height: 100 }}
            themeMode={props.themeMode}
          />
        )}
      </Grid>
      <Grid
        xs={12}
        sm={8}
      >
        <Typography sx={{ textAlign: "justify" }}>{props.body}</Typography>
      </Grid>
    </Grid>
  );
}

export default HowToSlideComponent;
