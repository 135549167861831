import { inputHandler, InputProps, KeyOfType } from "../../utils/input-handler";
import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface Props<T, V extends number | null> extends InputProps<T, V> {
  label?: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
  isFocusOnRender?: boolean;
}

function NumberComponent<T, V extends number | null>(props: Props<T, V>) {
  const { isRequired = false, isFullWidth = true, isFocusOnRender = false } = props;
  const [isError, setIsError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocusOnRender) {
      inputRef.current?.focus();
    }
  }, [isFocusOnRender]);

  function handleChange(newValue: string) {
    if (!newValue) {
      inputHandler(props.obj, props.objKey as unknown as KeyOfType<T, number | null>, props.setObj, null);
    }

    const number = parseInt(newValue);
    if (!isNaN(number) && number >= 0 && number < 1000) {
      inputHandler(props.obj, props.objKey as unknown as KeyOfType<T, number>, props.setObj, number);
      if (isError) {
        setIsError(false);
      }
    } else {
      setIsError(true);
    }
  }

  return (
    <TextField
      color="secondary"
      variant="standard"
      inputRef={inputRef}
      autoFocus={isFocusOnRender}
      error={isError}
      required={isRequired}
      label={props.label}
      fullWidth={isFullWidth}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      value={props.obj[props.objKey] || props.obj[props.objKey] === 0 ? props.obj[props.objKey] : ""}
      onChange={(newValue) => handleChange(newValue.target.value)}
    />
  );
}

export default NumberComponent;
