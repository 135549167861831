import { formatDate } from "../utils/date-time-helpers";

export interface TableFilter {
  isActive: boolean;
  byDateRange: boolean;
  startDate: string;
  endDate: string;
}
export class TableFilterInit implements TableFilter {
  isActive: boolean;
  byDateRange: boolean;
  startDate: string;
  endDate: string;

  constructor() {
    const newDateString = formatDate(new Date());

    this.byDateRange = false;
    this.isActive = false;
    this.startDate = newDateString;
    this.endDate = newDateString;
  }
}
