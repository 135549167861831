import { css, keyframes, Theme } from "@emotion/react";

const pulse = keyframes({
  "0%": {
    transform: "scale(0.95)",
  },
  "70%": {
    transform: "scale(1.05)",
    boxShadow: "0 0 0 10px rgba(0, 100, 0, 0)",
  },
  "100%": {
    transform: "scale(0.95)",
    boxShadow: "0 0 0 0 rgba(0, 100, 0, 0)",
  },
});

export const pulsingButton = (theme: Theme) =>
  css({
    boxShadow: `0 0 0 0 ${theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"}`,
    animation: `${pulse} 1.5s infinite`,
    "&:hover": { animation: "none" },
  });
