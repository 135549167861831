import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Normalize, TFunction, useTranslation } from "react-i18next";
import { Reading } from "../../data-models/reading";
import { Order } from "./Table";
import { translation } from "../../translations/en/translation";
import { Fragment } from "react";

interface HeadCell {
  id: keyof Reading;
  labelKey: Normalize<typeof translation.translation>;
  labelKeyMobile?: Normalize<typeof translation.translation>;
  minWidth?: string | number;
  maxWidth?: string | number;
  align?: "left" | "center" | "right" | "inherit" | "justify" | undefined;
  hideMobile?: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "date",
    labelKey: "table.date",
    minWidth: "100px",
  },
  {
    id: "time",
    labelKey: "table.time",
    maxWidth: "50px",
    hideMobile: true,
  },
  {
    id: "systolic",
    labelKey: "table.systolic",
    labelKeyMobile: "table.systolicMobile",
    maxWidth: "60px",
  },
  {
    id: "diastolic",
    labelKey: "table.diastolic",
    labelKeyMobile: "table.diastolicMobile",
    maxWidth: "60px",
  },
  {
    id: "pulse",
    labelKey: "table.pulse",
    maxWidth: "50px",
  },
  {
    id: "note",
    labelKey: "table.note",
    minWidth: "250px",
    hideMobile: true,
  },
];

interface Props {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Reading) => void;
  order: Order;
  orderBy: string;
}

function TableHeadComponent(props: Props) {
  const { t } = useTranslation();

  const createSortHandler = (property: keyof Reading) => (event: React.MouseEvent<unknown>) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          position: "sticky",
          top: { xs: 112 - 3, sm: 128 - 3 },
          backgroundColor: "tableColors.background",
          backgroundImage: "tableColors.backgroundGradient",
          boxShadow: 5,
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            variant="body"
            align={headCell.align}
            sx={{
              display: { xs: headCell.hideMobile ? "none" : "table-cell", sm: "table-cell" },
              borderBottom: 0,
              color: "tableColors.color",
              fontWeight: 700,
              "&:.MuiTableSortLabel-root": {
                color: "tableColors.color",
                fontWeight: 700,
              },
            }}
            style={{ minWidth: headCell.minWidth ?? "auto", maxWidth: headCell.maxWidth ?? "auto" }}
            key={headCell.id}
            sortDirection={props.orderBy === headCell.id ? props.order : false}
          >
            <TableSortLabel
              sx={{
                "&.Mui-active": {
                  color: "tableColors.color",
                },
                "&.Mui-active .MuiTableSortLabel-icon": {
                  color: "tableColors.color",
                },
              }}
              active={props.orderBy === headCell.id}
              direction={props.orderBy === headCell.id ? props.order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <TableHeadLabel
                headCell={headCell}
                t={t}
              />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeadComponent;

interface TableHeadLabelProps {
  headCell: HeadCell;
  t: TFunction<"translation", undefined>;
}

function TableHeadLabel(props: TableHeadLabelProps) {
  const { headCell, t } = props;

  return (
    <Fragment>
      <Box sx={{ display: { xs: headCell.labelKeyMobile ? "none" : "flex", sm: "flex" } }}>
        {t(headCell.labelKey) as string}
      </Box>
      <Box sx={{ display: { xs: headCell.labelKeyMobile ? "flex" : "none", sm: "none" } }}>
        {headCell.labelKeyMobile && (t(headCell.labelKeyMobile) as string)}
      </Box>
    </Fragment>
  );
}
