import { KeyOfType } from "../utils/input-handler";
import { Reading } from "./reading";

export type ReadingsAverages = Record<KeyOfType<Reading, number | null>, number | null>;

export class ReadingsAveragesInit implements ReadingsAverages {
  systolic: number | null;
  diastolic: number | null;
  pulse: number | null;

  constructor() {
    this.systolic = null;
    this.diastolic = null;
    this.pulse = null;
  }
}
