import { Route, Routes } from "react-router-dom";
import HomeComponent from "../components/home/Home";
import AuthGuardComponent from "./authentication/AuthGuard";
import CognitoAuthComponent from "./authentication/CognitoAuth";
import LoginComponent from "./authentication/Login";
import LogoutComponent from "./authentication/Logout";

interface Props {
  inDelay: number;
}

function RouterComponent(props: Props) {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <AuthGuardComponent>
            <HomeComponent />
          </AuthGuardComponent>
        }
      />
      <Route
        path="/"
        element={
          <AuthGuardComponent>
            <HomeComponent />
          </AuthGuardComponent>
        }
      />
      <Route
        path="login"
        element={<LoginComponent inDelay={props.inDelay} />}
      />
      <Route
        path="signout"
        element={<LogoutComponent />}
      />
      <Route
        path="/signin/oauth2/cognito"
        element={<CognitoAuthComponent inDelay={props.inDelay} />}
      />
    </Routes>
  );
}

export default RouterComponent;
