import { configureStore } from "@reduxjs/toolkit";
import readingsReducer from "../readings";

export const store = configureStore({
  reducer: {
    readings: readingsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type FetchStatus = "idle" | "pending" | "fulfilled" | "noContent" | "rejected";
export interface ThunkApi {
  rejectValue: string;
  state: RootState;
}
