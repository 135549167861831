/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import { Box, createTheme, CssBaseline, Fade, ThemeProvider } from "@mui/material";
import getTheme, { ThemeMode } from "./styles/themes";
import AppBarComponent from "./components/app-bar/AppBar";
import "./i18n";
import { useTranslation } from "react-i18next";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getStorageUserSetting, setStorageUserSetting } from "./services/settings";
import { app } from "./styles/app-styles";
import PwaUpdateComponent from "./components/pwa/PwaUpdate";
import RouterComponent from "./router/Router";

export const appVersion = "1.1.2";

function App() {
  const [themeMode, setThemeMode] = useState(getStorageUserSetting<ThemeMode>("themeMode"));
  const [appIn, setAppIn] = useState<boolean>(true);
  const theme = createTheme(getTheme(themeMode));
  const { t, i18n } = useTranslation();
  // Main animation timings
  const inDuration = 2000;
  const outDuration = 500;
  const inDelay = 1000;

  document.documentElement.lang = i18n.language;
  document.title = t("index.title");

  const favIcon = document.getElementById("favicon") as HTMLLinkElement;
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    favIcon.href = `${process.env.PUBLIC_URL}/favicon-dark.svg`;
  } else {
    favIcon.href = `${process.env.PUBLIC_URL}/favicon-light.svg`;
  }

  useEffect(() => {
    setStorageUserSetting<ThemeMode>("themeMode", themeMode);
  }, [themeMode]);

  // this removes the body overflow for the initial animation
  // useEffect(() => {
  //   document.body.style.overflow = "hidden";
  //   setTimeout(() => {
  //     document.body.style.overflow = "auto";
  //   }, inDuration + 1000);
  // }, [appIn]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppBarComponent
          themeMode={themeMode}
          setThemeMode={setThemeMode}
          setAppIn={setAppIn}
          outDuration={outDuration}
        ></AppBarComponent>

        <Fade
          in={appIn}
          timeout={{ enter: inDuration, exit: outDuration }}
        >
          <Box css={app}>
            <RouterComponent inDelay={inDelay} />
          </Box>
        </Fade>
        <PwaUpdateComponent />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
