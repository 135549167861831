/** @jsxImportSource @emotion/react */

import React, { useEffect, Dispatch } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, ListItemIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { listColor } from "../styles/styles";

const languageItems = [
  {
    description: "Français",
    lang: "fr",
  },
  {
    description: "English",
    lang: "en",
  },
];

interface Props {
  langMenuClick: boolean;
  setLangMenuClick: Dispatch<React.SetStateAction<boolean>>;
}

function LangMenuComponent(props: Props) {
  const { i18n } = useTranslation();
  const { langMenuClick, setLangMenuClick } = props;

  useEffect(() => {
    if (langMenuClick) {
      const newLang = languageItems.find((l) => l.lang !== i18n.language)?.lang;
      i18n.changeLanguage(newLang);
      setLangMenuClick(false);
    }
  }, [langMenuClick, setLangMenuClick, i18n]);

  return (
    <Box sx={{ display: "flex" }}>
      <ListItemIcon css={listColor}>
        <LanguageIcon />
      </ListItemIcon>
      {languageItems.find((l) => l.lang !== i18n.language)?.description}
    </Box>
  );
}

export default LangMenuComponent;
