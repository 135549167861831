import { DialogContentText, IconButton } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableFilter } from "../../../data-models/table-filter";
import { KeyOfType } from "../../../utils/input-handler";
import DatePickerComponent from "../../inputs/DatePicker";
import ReplayIcon from "@mui/icons-material/Replay";
import SwitchComponent from "../../inputs/Switch";
import { useAppSelector } from "../../../stores/app/hooks";
import { sortReadingsByDateTime } from "../../../utils/sort-readings";
import { formatDate } from "../../../utils/date-time-helpers";

interface Props {
  tableFilter: TableFilter;
  setTableFilter: Dispatch<SetStateAction<TableFilter>>;
}

function DateFilterComponent(props: Props) {
  const { t } = useTranslation();
  const { readings } = useAppSelector((state) => state.readings);
  const [oldestDate, setOldestDate] = useState<string>("2022-01-01");

  useEffect(() => {
    const sortedReadings = sortReadingsByDateTime(readings);
    setOldestDate(sortedReadings[0].date);
  }, [readings]);

  return (
    <Grid
      container
      rowSpacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        justifyContent="center"
        xs={9}
        sm={11}
      >
        <Grid xs={"auto"}>
          <SwitchComponent<TableFilter, boolean>
            label={t("filter.dateLabel")}
            obj={props.tableFilter}
            objKey="byDateRange"
            setObj={props.setTableFilter}
          />
        </Grid>
      </Grid>
      <DatePickerFilter
        label={t("filter.startDate")}
        datePickerLabel={t("filter.datePickerLabel")}
        objKey="startDate"
        tableFilter={props.tableFilter}
        setTableFilter={props.setTableFilter}
        oldestDate={oldestDate}
      />
      <DatePickerFilter
        label={t("filter.endDate")}
        datePickerLabel={t("filter.datePickerLabel")}
        objKey="endDate"
        tableFilter={props.tableFilter}
        setTableFilter={props.setTableFilter}
        oldestDate={oldestDate}
      />
    </Grid>
  );
}

export default DateFilterComponent;

interface DatePickerFilterProps extends Props {
  label: string;
  datePickerLabel: string;
  objKey: KeyOfType<TableFilter, string>;
  oldestDate: string;
}

function DatePickerFilter(props: DatePickerFilterProps) {
  function handleDateReset(key: "startDate" | "endDate") {
    if (key === "startDate") {
      props.setTableFilter({ ...props.tableFilter, startDate: props.oldestDate });
    }
    if (key === "endDate") {
      props.setTableFilter({ ...props.tableFilter, endDate: formatDate(new Date()) });
    }
  }

  return (
    <Grid
      container
      xs={12}
      sm={6}
      rowSpacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        xs={9}
        sm={11}
      >
        <DialogContentText>{props.label}</DialogContentText>
      </Grid>
      <Grid
        xs={8}
        sm={9}
      >
        <DatePickerComponent<TableFilter, string>
          label={props.datePickerLabel}
          isDisabled={!props.tableFilter.byDateRange}
          obj={props.tableFilter}
          objKey={props.objKey}
          setObj={props.setTableFilter}
        />
      </Grid>
      <Grid
        xs={1}
        sm={2}
      >
        <IconButton
          disabled={!props.tableFilter.byDateRange}
          onClick={() => handleDateReset(props.objKey)}
        >
          <ReplayIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
