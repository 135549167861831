import { CognitoAuth, StorageHelper, type CognitoAuthOptions } from "amazon-cognito-auth-js";
import historyRouter from "../router/history-router";

const authData: CognitoAuthOptions = {
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  AppWebDomain: process.env.REACT_APP_COGNITO_APP_DOMAIN,
  TokenScopesArray: ["openid", "email"],
  RedirectUriSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URI,
  RedirectUriSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URI_SIGNOUT,
  UserPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
};

export default class AuthService {
  private static _cognitoAuth: CognitoAuth | null = null;
  private static _storageHelper: StorageHelper | null = null;
  private static _token: string;
  private static _parsing = false;
  private readonly cognitoAuth: CognitoAuth;
  private readonly storageHelper: StorageHelper;

  constructor() {
    if (!AuthService._cognitoAuth) {
      AuthService._cognitoAuth = new CognitoAuth(authData);

      this.setCognitoAuth(AuthService._cognitoAuth);
    }
    if (!AuthService._storageHelper) {
      AuthService._storageHelper = new StorageHelper();
    }

    this.cognitoAuth = AuthService._cognitoAuth;
    this.storageHelper = AuthService._storageHelper;
  }

  private setCognitoAuth(cognitoAuth: CognitoAuth) {
    cognitoAuth.useCodeGrantFlow();
    cognitoAuth.userhandler = {
      onSuccess: () => {
        AuthService._token = cognitoAuth.getSignInUserSession().getAccessToken().getJwtToken();
        historyRouter.replace("/");
        AuthService._parsing = false;
      },
      onFailure: () => {
        historyRouter.replace("/login");
        AuthService._parsing = false;
      },
    };
  }

  public async fetchTokenAsync(): Promise<string> {
    this.cognitoAuth.getSession();

    // arbitrary delay because of lack of async get token from aws cognito lib
    const delay = new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    return delay.then(() => {
      return AuthService._token;
    });
  }

  public getToken() {
    return AuthService._token;
  }

  public isUserSignedIn() {
    return this.cognitoAuth.isUserSignedIn();
  }

  public login() {
    this.cognitoAuth.getSession();
  }

  public logout() {
    if (AuthService._token) {
      this.cognitoAuth.signOut();
      const userInfoKey = this.getUserInfoStorageKey();
      const storage = this.storageHelper.getStorage();
      storage.removeItem(userInfoKey);
    }
  }

  public parseCognitoResponse(url: string) {
    if (!AuthService._parsing) {
      AuthService._parsing = true;
      this.cognitoAuth.parseCognitoWebResponse(url);
    }
  }

  private getUserInfoStorageKey() {
    const keyPrefix = "CognitoIdentityServiceProvider." + this.cognitoAuth.getClientId();
    const tokenUserName = this.cognitoAuth.getUsername();
    const userInfoKey = keyPrefix + "." + tokenUserName + ".userInfo";
    return userInfoKey;
  }
}
