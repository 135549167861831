import { Toolbar, Typography } from "@mui/material";
import ToolBarFilterComponent from "../tool-bar-filter/ToolBarFilter";

interface Props {
  label: string;
  filter?: boolean;
}

function ContainerToolBarComponent(props: Props) {
  const { filter = false } = props;

  return (
    <Toolbar
      sx={{
        zIndex: 5,
        backgroundColor: "tableColors.background",
        borderRadius: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: 10,
        position: "sticky",
        top: { xs: 56 - 3, sm: 64 - 3 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%", color: "tableColors.color" }}
        variant="h6"
        component="div"
      >
        {props.label}
      </Typography>

      {filter && <ToolBarFilterComponent />}
    </Toolbar>
  );
}

export default ContainerToolBarComponent;
