/** @jsxImportSource @emotion/react */

import { useRef, useEffect, useState, Fragment } from "react";
import type { ChartData, ChartArea, ChartOptions } from "chart.js";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, LineController } from "chart.js";
import { Chart } from "react-chartjs-2";
import { Box, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import ContainerToolBarComponent from "../home/content-container/ContainerToolBar";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../stores/app/hooks";
import { css } from "@emotion/react";

const styles = () =>
  css({
    display: "grid",
    alignItems: "center",
    height: "calc(100% - 64px)",
    marginTop: "auto",
    marginBottom: "auto",
  });

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, LineController);
ChartJS.defaults.font.family = "'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

function ChartComponent() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { filteredReadings } = useAppSelector((state) => state.readings);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const chartRef = useRef<ChartJS>(null);
  const chart = chartRef.current;
  const [chartData, setChartData] = useState<ChartData<"line">>({
    datasets: [],
  });
  const [gradientUpdated, setGradienUpdated] = useState(false);

  useEffect(() => {
    if (!chart) {
      return;
    }

    const data: ChartData = {
      labels: filteredReadings.map((r) => `${r.date} ${r.time}`),
      datasets: [
        {
          label: t("chart.systolic"),
          data: filteredReadings.map((r) => r.systolic),
          tension: 0.25,
          borderColor: getGradient(chart.ctx, chart.chartArea, chart.scales["y"] as LinearScale, true, true),
          pointHitRadius: 5,
        },
        {
          label: t("chart.diastolic"),
          data: filteredReadings.map((r) => r.diastolic),
          tension: 0.25,
          borderColor: getGradient(chart.ctx, chart.chartArea, chart.scales["y"] as LinearScale, false, true),
          pointHitRadius: 5,
        },
      ],
    };

    setGradienUpdated(false);
    setChartData(data as ChartData<"line">);
  }, [filteredReadings, chart, t]);

  const lightGridColor = "rgba(0, 0, 0, 0.3)";
  const darkGridColor = "rgba(255, 255, 255, 0.3)";
  const options: ChartOptions = {
    animation: {
      onComplete(event) {
        if (event.chart && (event.chart.scales["y"] as LinearScale).min !== 0 && !gradientUpdated) {
          handleComplete(event.chart, t("chart.systolic"), t("chart.diastolic"));
          setGradienUpdated(true);
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          color: theme.palette.mode === "dark" ? darkGridColor : lightGridColor,
        },
      },
      x: {
        grid: {
          color: theme.palette.mode === "dark" ? darkGridColor : lightGridColor,
        },
        ticks: {
          display: !isMobile,
        },
      },
    },
  };

  return (
    <Fragment>
      <ContainerToolBarComponent
        label={t("chart.title")}
        filter={true}
      />

      <Box css={styles}>
        <Typography
          sx={{ paddingY: 2 }}
          variant="h5"
        >
          {t("chart.systolic")}
        </Typography>
        <Box id="print-none">
          <Chart
            ref={chartRef}
            type="line"
            data={chartData}
            options={options}
          />
        </Box>
        <Box
          id="print-only"
          sx={{ display: "none" }}
        >
          <img
            id="print-chart"
            alt="Chart print area"
          />
        </Box>
        <Typography
          sx={{ paddingTop: 2 }}
          variant="h5"
        >
          {t("chart.diastolic")}
        </Typography>
      </Box>
      <Toolbar />
    </Fragment>
  );
}

export default ChartComponent;

function handleComplete(chart: ChartJS, systolicKey: string, diastolicKey: string) {
  const systolic = chart.data.datasets.find((d) => d.label === systolicKey);
  const diastolic = chart.data.datasets.find((d) => d.label === diastolicKey);

  if (systolic) {
    systolic.borderColor = getGradient(chart.ctx, chart.chartArea, chart.scales["y"] as LinearScale, true, false);
  }
  if (diastolic) {
    diastolic.borderColor = getGradient(chart.ctx, chart.chartArea, chart.scales["y"] as LinearScale, false, false);
  }

  chart.update();
  renderPrintArea(chart);
}

function getGradient(
  ctx: CanvasRenderingContext2D,
  area: ChartArea,
  linearScale: LinearScale,
  isSystolic: boolean,
  isFirstRender = true
) {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  if (isSystolic) {
    return systolicGradient(gradient, linearScale, isFirstRender);
  } else {
    return diastolicGradient(gradient, linearScale, isFirstRender);
  }
}

function systolicGradient(gradient: CanvasGradient, linearScale: LinearScale, isFirstRender: boolean) {
  if (isFirstRender) {
    gradient.addColorStop(0.6, "green");
    gradient.addColorStop(0.7, "yellow");
    gradient.addColorStop(0.8, "orange");
    gradient.addColorStop(1, "red");
  } else {
    gradient.addColorStop(returnColorStop(115, linearScale), "green");
    gradient.addColorStop(returnColorStop(125, linearScale), "yellow");
    gradient.addColorStop(returnColorStop(135, linearScale), "orange");
    gradient.addColorStop(returnColorStop(145, linearScale), "red");
  }

  return gradient;
}

function diastolicGradient(gradient: CanvasGradient, linearScale: LinearScale, isFirstRender: boolean) {
  if (isFirstRender) {
    gradient.addColorStop(0.2, "green");
    gradient.addColorStop(0.25, "yellow");
    gradient.addColorStop(0.3, "orange");
    gradient.addColorStop(0.4, "red");
  } else {
    gradient.addColorStop(returnColorStop(75, linearScale), "green");
    gradient.addColorStop(returnColorStop(85, linearScale), "yellow");
    gradient.addColorStop(returnColorStop(90, linearScale), "orange");
    gradient.addColorStop(returnColorStop(100, linearScale), "red");
  }

  return gradient;
}

function returnColorStop(value: number, linearScale: LinearScale) {
  const min = linearScale.min;
  const max = linearScale.max;
  const range = max - min;
  const rangeValueDiff = value - min;
  let result = rangeValueDiff / range;

  if (result < 0) {
    result = 0;
  }
  if (result > 1) {
    result = 1;
  }

  return parseFloat(result.toFixed(2));
}

function renderPrintArea(chart: ChartJS) {
  const url = chart.toBase64Image();
  const img = document.getElementById("print-chart") as HTMLImageElement;
  if (img) {
    img.src = url;
  }
}
