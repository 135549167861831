/** @jsxImportSource @emotion/react */

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { appVersion } from "../../App";
import DialogTransition from "./transition/DialogTransition";
import { dialogTitle } from "../../styles/app-styles";
import LogoIconComponent from "../logo-page/LogoIcon";

interface Props {
  openAboutDialog: boolean;
  setOpenAboutDialog: Dispatch<SetStateAction<boolean>>;
}

function AboutDialogComponent(props: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      fullScreen={fullScreen}
      TransitionComponent={DialogTransition}
      open={props.openAboutDialog}
      onClose={() => props.setOpenAboutDialog(false)}
    >
      <DialogTitle css={dialogTitle}>{t("about.title")}</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6">{`Version ${appVersion}`}</Typography>
          <br />
          <LogoIconComponent
            themeMode={theme.palette.mode}
            sx={{ fontSize: 100 }}
          />
        </Box>
        <br />
        <br />
        <Typography fontWeight={700}>{t("about.body1")}</Typography>
        <br />
        <a
          href="mailto:hurteau.anthony123@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            sx={{
              fontWeight: 700,
              color: "text.primary",
              textDecoration: "underline",
              transition: "0.5s",
              ":hover": { color: "text.secondary" },
            }}
          >
            hurteau.anthony123@gmail.com
          </Typography>
        </a>
        <br />
        <Typography fontWeight={700}>{t("about.body2")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => props.setOpenAboutDialog(false)}
          autoFocus
        >
          {t("about.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AboutDialogComponent;
