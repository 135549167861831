import { inputHandler, InputProps, KeyOfType } from "../../utils/input-handler";
import { TextField } from "@mui/material";

interface Props<T, V extends string | null> extends InputProps<T, V> {
  label?: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
}

function MultilineComponent<T, V extends string | null>(props: Props<T, V>) {
  const { isRequired = false, isFullWidth = true } = props;

  return (
    <TextField
      color="secondary"
      variant="standard"
      required={isRequired}
      label={props.label}
      multiline
      fullWidth={isFullWidth}
      inputProps={{ maxLength: 400 }}
      value={props.obj[props.objKey] ? props.obj[props.objKey] : ""}
      onChange={(newValue) =>
        inputHandler(props.obj, props.objKey as unknown as KeyOfType<T, string>, props.setObj, newValue.target.value)
      }
    />
  );
}

export default MultilineComponent;
