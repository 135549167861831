import { Fragment, useEffect } from "react";
import AuthService from "../../services/auth";
import LogoPageComponent from "../../components/logo-page/LogoPage";

const authService = new AuthService();

interface Props {
  inDelay: number;
}

function LoginComponent(props: Props) {
  useEffect(() => {
    setTimeout(() => {
      authService.login();
    }, props.inDelay);
  }, [props.inDelay]);

  return (
    <Fragment>
      <LogoPageComponent />
    </Fragment>
  );
}
export default LoginComponent;
