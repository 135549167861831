import { formatDate, formatTime } from "../utils/date-time-helpers";
import { BaseEntity } from "./base-entity";

export interface Reading extends BaseEntity {
  date: string;
  time: string;
  systolic: number | null;
  diastolic: number | null;
  pulse: number | null;
  note: string | null;
}

export type ReadingType = "systolic" | "diastolic" | "pulse";

export class ReadingInit implements Reading {
  id: string;
  date: string;
  time: string;
  systolic: number | null;
  diastolic: number | null;
  pulse: number | null;
  note: string | null;

  constructor() {
    this.id = "0";
    this.date = formatDate(new Date());
    this.time = formatTime(new Date());
    this.systolic = null;
    this.diastolic = null;
    this.pulse = null;
    this.note = null;
  }
}
