import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { dateStringFormat, formatDate, parseDateString } from "../../utils/date-time-helpers";
import { inputHandler, InputProps } from "../../utils/input-handler";

interface Props<T, V extends string> extends InputProps<T, V> {
  label: string;
  isDisabled?: boolean;
  dateFormat?: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
}

function DatePickerComponent<T, V extends string>(props: Props<T, V>) {
  const { isDisabled = false, isRequired = false, isFullWidth = true, dateFormat = dateStringFormat } = props;
  const [value, setValue] = useState<Date | null>(parseDateString(props.obj[props.objKey] as V));

  useEffect(() => {
    setValue(parseDateString(props.obj[props.objKey] as V));
  }, [props.obj, props.objKey]);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!value) {
      setIsError(true);
    }
  }, [value]);

  function handleDateChange(newDate: Date | null) {
    if (newDate) {
      setValue(newDate);
      if (!isNaN(newDate.getTime())) {
        const dateString = formatDate(newDate, dateFormat);
        inputHandler(props.obj, props.objKey, props.setObj, dateString as V);
      }
    }
  }

  return (
    <DatePicker
      disableFuture
      label={props.label}
      inputFormat={dateFormat}
      disabled={isDisabled}
      value={value}
      onChange={(newValue) => handleDateChange(newValue)}
      renderInput={(params) => (
        <TextField
          color="secondary"
          variant="standard"
          required={isRequired}
          fullWidth={isFullWidth}
          error={isError}
          {...params}
        />
      )}
    />
  );
}

export default DatePickerComponent;
