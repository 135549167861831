import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactComponent as LogoDark } from "../../assets/logo-dark.svg";
import { ReactComponent as LogoLight } from "../../assets/logo-light.svg";
import { ThemeMode } from "../../styles/themes";

interface Props {
  themeMode: ThemeMode;
  sx?: SxProps<Theme> | undefined;
}

function LogoIconComponent(props: Props) {
  return <SvgIcon sx={props.sx}> {props.themeMode === "light" ? <LogoLight /> : <LogoDark />}</SvgIcon>;
}

export default LogoIconComponent;
