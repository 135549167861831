import { Dispatch, Fragment, SetStateAction, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemeMode } from "../../styles/themes";
import MenuComponent from "./menu/Menu";
import { Button } from "@mui/material";
import AboutDialogComponent from "../dialogs/AboutDialog";
import LogoIconComponent from "../logo-page/LogoIcon";

export interface AppBarProps {
  themeMode: ThemeMode;
  setThemeMode: Dispatch<SetStateAction<ThemeMode>>;
  setAppIn: Dispatch<SetStateAction<boolean>>;
  outDuration: number;
}

function AppBarComponent(props: AppBarProps) {
  const { t } = useTranslation();
  const [openAboutDialog, setOpenAboutDialog] = useState(false);

  return (
    <Fragment>
      <AppBar
        elevation={2}
        position="fixed"
      >
        <Toolbar sx={{ paddingX: { xs: 0, sm: "24px" } }}>
          <Button
            color="secondary"
            sx={{ display: "flex", alignItems: "center" }}
            component={Link}
            to="/"
          >
            <LogoIconComponent
              themeMode={props.themeMode}
              sx={{
                height: { xs: 35, sm: 50 },
                width: { xs: 35, sm: 50 },
              }}
            />
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                letterSpacing: ".1rem",
                color: "inherit",
                marginLeft: 2,
              }}
            >
              {t("appBar.title")}
            </Typography>
            <Typography
              sx={{
                display: { xs: "flex", md: "none" },
                color: "inherit",
                fontWeight: "bold",
                marginLeft: 1,
              }}
            >
              {t("appBar.title")}
            </Typography>
          </Button>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <MenuComponent
              themeMode={props.themeMode}
              setThemeMode={props.setThemeMode}
              setOpenAboutDialog={setOpenAboutDialog}
              setAppIn={props.setAppIn}
              outDuration={props.outDuration}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <AboutDialogComponent
        openAboutDialog={openAboutDialog}
        setOpenAboutDialog={setOpenAboutDialog}
      />
    </Fragment>
  );
}
export default AppBarComponent;
