/** @jsxImportSource @emotion/react */

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Reading } from "../../data-models/reading";
import { useTranslation } from "react-i18next";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import TableHeadComponent from "./TableHead";
import { KeyOfType } from "../../utils/input-handler";
import { ReadingsAverages, ReadingsAveragesInit } from "../../data-models/readings-averages";
import { ReadingFormDialog } from "../dialogs/ReadingFormDialog";
import { css, Theme } from "@emotion/react";
import ContainerToolBarComponent from "../home/content-container/ContainerToolBar";
import { useAppSelector } from "../../stores/app/hooks";
import TableCellComponent from "./TableCell";
import { Box } from "@mui/material";

const cell = (theme: Theme) =>
  css({
    color: theme.palette.tableColors.color,
    fontWeight: 700,
  });

export type Order = "asc" | "desc";

interface Props {
  setReadingFormDialog: Dispatch<SetStateAction<ReadingFormDialog | undefined>>;
}

function TableComponent(props: Props) {
  const { t } = useTranslation();
  const { filteredReadings } = useAppSelector((state) => state.readings);
  const [readingAverages, setReadingAverages] = useState<ReadingsAverages>(new ReadingsAveragesInit());
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Reading>("date");

  useEffect(() => {
    const readingsAverageobj = new ReadingsAveragesInit();

    (Object.keys(readingsAverageobj) as (keyof ReadingsAverages)[]).forEach(
      (key) =>
        (readingsAverageobj[key] = getReadingsAverage(key as KeyOfType<Reading, number | null>, filteredReadings))
    );
    setReadingAverages(readingsAverageobj);
  }, [filteredReadings]);

  function handleSelection(reading: Reading) {
    const readingFormDialog = new ReadingFormDialog(reading);
    props.setReadingFormDialog(readingFormDialog);
  }

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof Reading) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Fragment>
      <ContainerToolBarComponent
        label={t("table.title")}
        filter={true}
      />
      <TableContainer
        component={Paper}
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          overflowX: "visible",
        }}
      >
        <Table>
          <TableHeadComponent
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {filteredReadings
              .slice()
              .sort(getComparator(order, orderBy))
              .map((reading, index) => {
                const labelId = `row-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={() => handleSelection(reading)}
                    tabIndex={-1}
                    key={reading.id}
                  >
                    <TableCell
                      id={labelId}
                      component="th"
                      scope="row"
                    >
                      {reading.date}
                    </TableCell>
                    <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>{reading.time}</TableCell>
                    <TableCell>
                      <TableCellComponent
                        reading={reading.systolic}
                        readingType="systolic"
                      />
                    </TableCell>
                    <TableCell>
                      <TableCellComponent
                        reading={reading.diastolic}
                        readingType="diastolic"
                      />
                    </TableCell>
                    <TableCell>{reading.pulse}</TableCell>
                    <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>{reading.note}</TableCell>
                  </TableRow>
                );
              })}
            <TableRow
              key="reading-average-row"
              sx={{
                position: "sticky",
                bottom: { xs: 56 - 3, sm: 0 - 3 },
                zIndex: 2,
                backgroundColor: "tableColors.background",
                backgroundImage: "tableColors.backgroundGradient",
                boxShadow: 10,
              }}
            >
              <TableCell
                id="reading-average-cells"
                scope="row"
                padding="normal"
                css={cell}
                sx={{ borderRadius: 1, borderTopRightRadius: 0, borderTopLeftRadius: 0, borderBottomRightRadius: 0 }}
              >
                <Box sx={{ display: { xs: "block", sm: "none" } }}>{t("table.average")}</Box>
              </TableCell>
              <TableCell
                css={cell}
                sx={{ display: { xs: "none", sm: "table-cell" } }}
              >
                {t("table.average")}
              </TableCell>
              <TableCell css={cell}>
                <TableCellComponent
                  reading={readingAverages.systolic}
                  readingType="systolic"
                />
              </TableCell>
              <TableCell css={cell}>
                <TableCellComponent
                  reading={readingAverages.diastolic}
                  readingType="diastolic"
                />
              </TableCell>
              <TableCell
                css={cell}
                sx={{
                  borderRadius: { xs: 1, sm: 0 },
                  borderTopRightRadius: { xs: 0, sm: 0 },
                  borderTopLeftRadius: { xs: 0, sm: 0 },
                  borderBottomLeftRadius: { xs: 0, sm: 0 },
                }}
              >
                {readingAverages.pulse}
              </TableCell>
              <TableCell
                sx={{
                  display: { xs: "none", sm: "table-cell" },
                  borderRadius: 1,
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              ></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}

export default TableComponent;

function getComparator<Key extends keyof Reading>(order: Order, orderBy: Key): (a: Reading, b: Reading) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getReadingsAverage(readingType: KeyOfType<Reading, number | null>, readings: Reading[]): number | null {
  if (readings.length > 0) {
    let readingsTotal = 0;
    readings.forEach((r) => (readingsTotal = readingsTotal + (r[readingType] ? (r[readingType] as number) : 0)));
    if (readingsTotal) {
      const average = readingsTotal / readings.length;
      return Number(average.toLocaleString(navigator.language, { maximumFractionDigits: 0, minimumFractionDigits: 0 }));
    }
  }
  return null;
}
