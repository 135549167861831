import { useEffect } from "react";
import AuthService from "../../services/auth";
import historyRouter from "../history-router";

const authService = new AuthService();

function LogoutComponent() {
  useEffect(() => {
    authService.logout();
    historyRouter.replace("/login");
  }, []);

  return null;
}

export default LogoutComponent;
