/** @jsxImportSource @emotion/react */

import { Box, Slide } from "@mui/material";
import { Fragment, ReactNode, useEffect, useState } from "react";
import ContainerWrapperComponent from "./ContainerWrapper";
import { ActiveNav } from "../Home";
import { css } from "@emotion/react";

const transitionBox = () =>
  css({
    height: "100%",
    width: "100%",
  });

export interface NavProps {
  nav: ActiveNav;
  activeNav: ActiveNav;
  previousNav: ActiveNav | undefined;
}

interface Props extends NavProps {
  children: ReactNode;
  contentCheck: boolean;
}

function NavigationTransitionComponent(props: Props) {
  const [slideIn, setSlideIn] = useState<boolean>(true);
  const [slideOut, setSlideOut] = useState<boolean>(false);
  const { nav, activeNav, previousNav } = props;

  const delay = 500;
  const duration = 250;

  useEffect(() => {
    if (nav === activeNav && nav !== previousNav) {
      setTimeout(() => {
        setSlideIn(true);
        setSlideOut(false);
      }, delay);
    }
    if (nav !== activeNav) {
      setSlideOut(true);
      setTimeout(() => {
        setSlideIn(false);
      }, delay);
    }
  }, [nav, activeNav, previousNav, slideIn, slideOut]);

  return (
    <Fragment>
      <Slide
        in={slideIn}
        direction="right"
        timeout={{ enter: duration, exit: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <Box css={transitionBox}>
          <Slide
            in={!slideOut}
            direction="left"
            timeout={{ enter: 0, exit: duration }}
            mountOnEnter
            unmountOnExit
          >
            <Box css={transitionBox}>
              <ContainerWrapperComponent contentCheck={props.contentCheck}>{props.children}</ContainerWrapperComponent>
            </Box>
          </Slide>
        </Box>
      </Slide>
    </Fragment>
  );
}

export default NavigationTransitionComponent;
