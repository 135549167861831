import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { getReadings } from "../../stores/readings";
import { useAppSelector, useAppDispatch } from "../../stores/app/hooks";
import { ReadingInit } from "../../data-models/reading";
import TableComponent from "../table/Table";
import ReadingFormDialogComponent, { ReadingFormDialog } from "../dialogs/ReadingFormDialog";
import ConfirmationDialogComponent, { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import SideBarComponent from "../action-menus/side-bar/SideBar";
import { getStorageUserSetting, setStorageUserSetting } from "../../services/settings";
import { Box } from "@mui/material";
import BottomNavigationComponent from "../action-menus/bottom-navigation/BottomNavigation";
import ChartComponent from "../chart/Chart";
import NavigationTransitionComponent from "./content-container/NavigationTransition";
import InfoComponent from "../info/Info";
import ErrorDialogComponent from "../dialogs/ErrorDialog";
import HowToDialogComponent, { PulsingButtons } from "../how-to-dialog/HowToDialog";

export type ActiveNav = "table" | "chart" | "info";
export enum Navs {
  table = "table",
  chart = "chart",
  info = "info",
}
export const actionNav = ["table", "chart"];

function HomeComponent() {
  const { error, fetchStatus } = useAppSelector((state) => state.readings);
  const dispatch = useAppDispatch();
  const [activeNav, setActiveNav] = useState<ActiveNav>(getStorageUserSetting<ActiveNav>("activeNav"));
  const [previousNav, setPreviousNav] = useState<ActiveNav>(getStorageUserSetting<ActiveNav>("activeNav"));
  const [readingFormDialog, setReadingFormDialog] = useState<ReadingFormDialog>();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationDialog>();
  const [openHowTo, setOpenHowTo] = useState(false);
  const [pulsingButton, setPulsingButton] = useState<PulsingButtons>();

  // initial call to get readings from store / api
  useEffect(() => {
    // small timeout improves animation smoothness
    setTimeout(() => {
      dispatch(getReadings());
    }, 500);
  }, [dispatch]);

  useEffect(() => {
    if (fetchStatus === "noContent") {
      setOpenHowTo(true);
      setPulsingButton("add");
    } else {
      setPulsingButton(undefined);
    }
  }, [fetchStatus]);

  // this clears the ReadingFormDialog object and sets confirmation to false when a deletion happens
  // this behavior is needed to remove a glitch when deleting a reading
  useEffect(() => {
    if (confirmation) {
      setReadingFormDialog(undefined);
      setConfirmation(false);
    }
  }, [confirmation]);

  useEffect(() => {
    setStorageUserSetting("activeNav", activeNav);
    setPreviousNav(activeNav);
  }, [activeNav]);

  function handleNav(nav: ActiveNav) {
    setPreviousNav(activeNav);
    setActiveNav(nav);
  }

  function addReading() {
    const newReadingFormDialog = new ReadingFormDialog(new ReadingInit(), true);
    setReadingFormDialog(newReadingFormDialog);
  }

  return (
    <Fragment>
      {/* Mobile Layout */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <BottomNavigationComponent
          addReading={addReading}
          handleNav={handleNav}
          activeNav={activeNav}
          pulsingButton={pulsingButton}
        >
          <NavigationWrapper
            activeNav={activeNav}
            previousNav={previousNav}
            setReadingFormDialog={setReadingFormDialog}
          />
        </BottomNavigationComponent>
      </Box>

      {/* Desktop layout */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <SideBarComponent
          addReading={addReading}
          handleNav={handleNav}
          activeNav={activeNav}
          pulsingButton={pulsingButton}
        >
          <NavigationWrapper
            activeNav={activeNav}
            previousNav={previousNav}
            setReadingFormDialog={setReadingFormDialog}
          />
        </SideBarComponent>
      </Box>

      <ReadingFormDialogComponent
        readingFormDialog={readingFormDialog}
        setConfirmationDialog={setConfirmationDialog}
        confirmation={confirmation}
        setConfirmation={setConfirmation}
      />
      <ConfirmationDialogComponent confirmationDialog={confirmationDialog} />
      <ErrorDialogComponent error={error} />
      <HowToDialogComponent
        open={openHowTo}
        setOpen={setOpenHowTo}
        setPulsingButton={setPulsingButton}
      />
    </Fragment>
  );
}

export default HomeComponent;

interface NavigationWrapperProps {
  activeNav: ActiveNav;
  previousNav: ActiveNav;
  setReadingFormDialog: Dispatch<SetStateAction<ReadingFormDialog | undefined>>;
}

function NavigationWrapper(props: NavigationWrapperProps) {
  return (
    <Fragment>
      <NavigationTransitionComponent
        nav="table"
        activeNav={props.activeNav}
        previousNav={props.previousNav}
        contentCheck={true}
      >
        <TableComponent setReadingFormDialog={props.setReadingFormDialog} />
      </NavigationTransitionComponent>
      <NavigationTransitionComponent
        nav="chart"
        activeNav={props.activeNav}
        previousNav={props.previousNav}
        contentCheck={true}
      >
        <ChartComponent />
      </NavigationTransitionComponent>
      <NavigationTransitionComponent
        nav="info"
        activeNav={props.activeNav}
        previousNav={props.previousNav}
        contentCheck={false}
      >
        <InfoComponent />
      </NavigationTransitionComponent>
    </Fragment>
  );
}
