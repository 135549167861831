declare module "@mui/material/styles/createPalette" {
  interface Palette {
    tableColors: {
      background: string;
      color: string;
      backgroundGradient: string;
    };
  }
}

export type ThemeMode = "light" | "dark";
export enum Modes {
  dark = "dark",
  light = "light",
}
// #eaeaea
const background = {
  [Modes.light]: { paper: "#f5f5f5", default: "#f9f9f9" },
  [Modes.dark]: { paper: "#1a1a1a", default: "#000000" },
};

const primary = {
  light: "#ffffff",
  main: "#f9f9f9",
  dark: "#c7c7c7",
  contrastText: "#000000",
};

const secondary = {
  light: "#383535",
  main: "#181818",
  dark: "#000000",
  contrastText: "#ffffff",
};

const tableBackgroundGradient = {
  [Modes.light]: "0",
  [Modes.dark]: "0",
};

const getTheme = (mode: ThemeMode) => ({
  shape: {
    borderRadius: 8,
  },
  palette: {
    mode,
    ...(mode === Modes.light
      ? {
          // Palette values for light mode
          common: { black: "#000", white: "#fff" },
          background: {
            paper: background.light.paper,
            default: background.light.default,
          },
          primary: {
            light: primary.light,
            main: primary.main,
            dark: primary.dark,
            contrastText: primary.contrastText,
          },
          secondary: {
            light: secondary.light,
            main: secondary.main,
            dark: secondary.dark,
            contrastText: secondary.contrastText,
          },
          text: {
            primary: "rgba(0, 0, 0, 1)",
            secondary: "rgba(0, 0, 0, 0.8)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
          },
          tableColors: {
            background: secondary.main,
            color: secondary.contrastText,
            backgroundGradient: `linear-gradient(rgba(255, 255, 255, ${tableBackgroundGradient[mode]}), rgba(255, 255, 255, ${tableBackgroundGradient[mode]}))`,
          },
        }
      : {
          // Palette values for dark mode
          common: { black: "#000", white: "#fff" },
          background: {
            paper: background.dark.paper,
            default: background.dark.default,
          },
          primary: {
            light: secondary.light,
            main: secondary.main,
            dark: secondary.dark,
            contrastText: secondary.contrastText,
          },
          secondary: {
            light: primary.light,
            main: primary.main,
            dark: primary.dark,
            contrastText: primary.contrastText,
          },
          text: {
            primary: "rgba(255, 255, 255, 1)",
            secondary: "rgba(207, 207, 207, 1)",
            disabled: "rgba(155, 155, 155, 1)",
            hint: "rgba(255, 255, 255, 1)",
          },
          tableColors: {
            background: secondary.main,
            color: secondary.contrastText,
            backgroundGradient: `linear-gradient(rgba(255, 255, 255, ${tableBackgroundGradient[mode]}), rgba(255, 255, 255, ${tableBackgroundGradient[mode]}))`,
          },
        }),
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child td": {
            borderBottom: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingRight: "5px",
          paddingLeft: "5px",
        },
      },
    },
  },
});

export default getTheme;
