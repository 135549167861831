/** @jsxImportSource @emotion/react */

import { SvgIconComponent } from "@mui/icons-material";
import { Button } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { ActiveNav } from "../../home/Home";
import { css, Theme } from "@emotion/react";
import { pulsingButton } from "../styles/styles";

const styles = (theme: Theme) =>
  css({
    minWidth: "40px",
    height: "40px",
    padding: "5px",
    transition: theme.transitions.create(["color", "background-color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  });

interface Props {
  nav?: ActiveNav | null;
  activeNav?: ActiveNav | null;
  handleNav?: (nav: ActiveNav) => void;
  handleAction?: () => void;
  pulsing?: boolean;
  icon: SvgIconComponent;
  xsSize?: number;
}

function BottomNavigationButtonComponent(props: Props) {
  const { pulsing = false, xsSize = 4, nav = null, activeNav = null } = props;
  return (
    <Grid xs={xsSize}>
      <Button
        color="secondary"
        css={[styles, pulsing && pulsingButton]}
        variant={nav && activeNav === nav ? "outlined" : "contained"}
        onClick={() => (nav ? props.handleNav?.(nav) : props.handleAction?.())}
      >
        {ButtonIcon(props.icon)}
      </Button>
    </Grid>
  );
}

export default BottomNavigationButtonComponent;

const ButtonIcon = (Icon: SvgIconComponent) => {
  return <Icon fontSize="large" />;
};
