/** @jsxImportSource @emotion/react */

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomError } from "../../data-models/custom-error";
import { dialogTitle } from "../../styles/app-styles";
import DialogTransition from "./transition/DialogTransition";

interface Props {
  error: CustomError | undefined;
}

export function ErrorDialogComponent(props: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.error) {
      setOpen(true);
    }
  }, [props.error]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      onClose={handleClose}
    >
      <DialogTitle css={dialogTitle}>{t("errorDialog.title")}</DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        {props.error?.status && (
          <DialogContentText>{`Status ${props.error?.status} ${props.error?.statusText}`}</DialogContentText>
        )}
        <DialogContentText>{props.error?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleClose}
          autoFocus
        >
          {t("errorDialog.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialogComponent;
