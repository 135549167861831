import axios, { AxiosError } from "axios";
import { BaseEntity } from "../data-models/base-entity";
import AuthService from "./auth";

declare module "axios" {
  export interface AxiosRequestConfig {
    _retry?: boolean;
  }
}

const apiUrl = process.env.REACT_APP_API_URL;

export default class ApiService {
  private readonly authService: AuthService;

  constructor() {
    this.authService = new AuthService();
    this.setInterceptors();
  }

  private setInterceptors() {
    axios.interceptors.request.use(
      async (config) => {
        const token = this.authService.getToken();
        if (token) {
          if (config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        } else if (!token) {
          if (config.headers) {
            const asyncToken = await this.authService.fetchTokenAsync();
            config.headers.Authorization = `Bearer ${asyncToken}`;
          }
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AxiosError) => {
        const originalRequest = error.config;
        if (error.response && [401].includes(error.response.status) && !originalRequest._retry) {
          originalRequest._retry = true;

          // retry delay
          const delay = new Promise<void>((resolve) => {
            setTimeout(() => {
              resolve();
            }, 1000);
          });

          return delay.then(async () => {
            const token = await this.authService.fetchTokenAsync();
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            return axios(originalRequest);
          });
        }

        return Promise.reject(error);
      }
    );
  }

  public async getAsync<T extends BaseEntity>(urlPath: string): Promise<T[]> {
    const url = apiUrl + urlPath;
    const response = await axios.get(url);
    return response.data as T[];
  }

  public async postAsync<T extends BaseEntity>(urlPath: string, body: T): Promise<T> {
    const url = apiUrl + urlPath;
    const response = await axios.post(url, body);
    return response.data as T;
  }

  public async putAsync<T extends BaseEntity>(urlPath: string, id: string, body: T): Promise<T> {
    const url = `${apiUrl}${urlPath}/${id}`;
    const response = await axios.put(url, body);
    return response.data as T;
  }
  public async deleteAsync<T extends BaseEntity>(urlPath: string, id: string): Promise<T> {
    const url = `${apiUrl}${urlPath}/${id}`;
    const response = await axios.delete(url);
    return response.data as T;
  }
}
