/** @jsxImportSource @emotion/react */

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Reading } from "../../data-models/reading";
import { useAppDispatch } from "../../stores/app/hooks";
import { createReading, deleteReading, updateReading } from "../../stores/readings";
import { KeyOfType } from "../../utils/input-handler";
import { ConfirmationDialog } from "./ConfirmationDialog";
import DatePickerComponent from "../inputs/DatePicker";
import MultilineComponent from "../inputs/Multiline";
import NumberComponent from "../inputs/Number";
import TimePickerComponent from "../inputs/TimePicker";
import DialogTransition from "./transition/DialogTransition";
import { dialogTitle } from "../../styles/app-styles";

export class ReadingFormDialog {
  newReading: Reading;
  isCreation: boolean;

  constructor(newReading: Reading, isCreation = false) {
    this.newReading = newReading;
    this.isCreation = isCreation;
  }
}

interface Props {
  readingFormDialog: ReadingFormDialog | undefined;
  setConfirmationDialog: Dispatch<SetStateAction<ConfirmationDialog | undefined>>;
  confirmation: boolean;
  setConfirmation: Dispatch<SetStateAction<boolean>>;
}

function ReadingFormDialogComponent(props: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [reading, setReading] = useState<Reading>({} as Reading);
  const [initialReading, setInitialReading] = useState<Reading>({} as Reading);
  const [validationState, setValidationState] = useState<boolean>(false);
  const { newReading, isCreation } = props.readingFormDialog || ({} as ReadingFormDialog);

  useEffect(() => {
    if (props.readingFormDialog) {
      setOpen(true);
      setReading(newReading);
      setInitialReading(newReading);
    }
  }, [props.readingFormDialog, newReading]);

  useEffect(() => {
    setTimeout(() => {
      if (reading) {
        const isValid = isStateValid(reading, initialReading, isCreation);
        setValidationState(isValid);
      }
    }, 200);
  }, [reading, initialReading, isCreation]);

  useEffect(() => {
    if (props.confirmation) {
      dispatch(deleteReading(reading.id));
      handleClose();
    }
  }, [props.confirmation, dispatch, reading]);

  const handleClose = () => {
    setOpen(false);
  };

  function submit() {
    const newReading = { ...reading };

    if (isCreation) {
      dispatch(createReading(newReading));
    } else {
      dispatch(updateReading(newReading));
    }
    handleClose();
  }

  function deleteReadingConfirmation() {
    const newConfirmationDialogue = new ConfirmationDialog(props.setConfirmation, t("readingForm.confirmationMessage"));
    props.setConfirmationDialog(newConfirmationDialogue);
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      fullScreen={fullScreen}
      open={open}
      TransitionComponent={DialogTransition}
      onClose={() => handleClose()}
    >
      <DialogTitle css={dialogTitle}>
        {isCreation ? t("readingForm.createTitle") : t("readingForm.updateTitle")}
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          component="form"
          autoComplete="off"
          container
          spacing={3}
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <DatePickerComponent<Reading, string>
              label={t("readingForm.date")}
              obj={reading}
              objKey="date"
              setObj={setReading}
              isRequired={true}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TimePickerComponent<Reading, string>
              label={t("readingForm.time")}
              obj={reading}
              objKey="time"
              setObj={setReading}
            />
          </Grid>
          <Grid
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
            }}
            md={4}
          ></Grid>
          <NumberField
            label={t("readingForm.systolic")}
            isRequired={true}
            isFocusOnRender={true}
            reading={reading}
            objKey="systolic"
            setReading={setReading}
          />
          <NumberField
            label={t("readingForm.diastolic")}
            isRequired={true}
            reading={reading}
            objKey="diastolic"
            setReading={setReading}
          />
          <NumberField
            label={t("readingForm.pulse")}
            isRequired={false}
            reading={reading}
            objKey="pulse"
            setReading={setReading}
          />
          <Grid xs={12}>
            <MultilineComponent<Reading, string | null>
              label={t("readingForm.note")}
              isRequired={false}
              obj={reading}
              objKey="note"
              setObj={setReading}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!validationState}
          color="success"
          onClick={() => submit()}
        >
          {isCreation ? t("readingForm.createOk") : t("readingForm.editOk")}
        </Button>
        {!isCreation ? (
          <Button
            color="error"
            onClick={() => deleteReadingConfirmation()}
          >
            {t("readingForm.delete")}
          </Button>
        ) : null}
        <Button
          color="secondary"
          onClick={() => handleClose()}
        >
          {t("readingForm.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReadingFormDialogComponent;

interface NumberFieldProps {
  label: string;
  objKey: KeyOfType<Reading, number | null>;
  isRequired: boolean;
  isFocusOnRender?: boolean;
  reading: Reading;
  setReading: Dispatch<SetStateAction<Reading>>;
}

function NumberField(props: NumberFieldProps) {
  const { isFocusOnRender = false } = props;

  return (
    <Grid
      xs={12}
      sm={6}
      md={4}
    >
      <NumberComponent<Reading, number | null>
        isFocusOnRender={isFocusOnRender}
        label={props.label}
        isRequired={props.isRequired}
        obj={props.reading}
        objKey={props.objKey}
        setObj={props.setReading}
      />
    </Grid>
  );
}

function isStateValid(newReading: Reading, initialReading: Reading, isCreation: boolean): boolean {
  const mandatoryReadingKeys = ["date", "systolic", "diastolic"];

  let allMandatoryFields = true;
  mandatoryReadingKeys.forEach((k) => {
    if (!newReading[k as keyof Reading]) {
      allMandatoryFields = false;
    }
  });

  if (!allMandatoryFields) {
    return false;
  }

  if (!isCreation) {
    if (JSON.stringify(newReading) === JSON.stringify(initialReading)) {
      return false;
    }
  }

  return true;
}
