import { Reading } from "../data-models/reading";
import { TableFilter } from "../data-models/table-filter";
import { parseDateString } from "../utils/date-time-helpers";
import { sortReadingsByDateTime } from "../utils/sort-readings";

export function filterReadings(readings: Reading[], tableFilter: TableFilter): Reading[] {
  let filteredReadings = sortReadingsByDateTime([...readings]);

  if (tableFilter.isActive) {
    if (tableFilter.byDateRange) {
      filteredReadings = filterByDateRange(filteredReadings, tableFilter);
    }
  }

  return filteredReadings;
}

function filterByDateRange(readings: Reading[], tableFilter: TableFilter): Reading[] {
  const startDate = parseDateString(tableFilter.startDate);
  const endDate = parseDateString(tableFilter.endDate);

  readings = readings.filter((r) => {
    const date = parseDateString(r.date);
    if (date >= startDate && date <= endDate) {
      return r;
    }
    return null;
  });

  return readings;
}
