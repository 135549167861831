import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translation as fr } from "./translations/fr/translation";
import { translation as en } from "./translations/en/translation";

export const i18nDefaultNS = "translation";
const languages = ["fr", "en"];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: { fr, en },
    defaultNS: i18nDefaultNS,
    fallbackLng: "fr",
    load: "languageOnly",
    supportedLngs: languages,
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ["localStorage", "navigator"],
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
  });

export default i18n;
