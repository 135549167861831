/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Box } from "@mui/material";
import { ReadingType } from "../../data-models/reading";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Grid from "@mui/system/Unstable_Grid";

const arrow = () =>
  css({
    height: 15,
    width: 15,
    marginLeft: 1,
  });

interface Props {
  reading: number | null;
  readingType: ReadingType;
}
function TableCellComponent(props: Props) {
  return (
    <Grid
      container
      alignItems="center"
    >
      <Grid>{props.reading}</Grid>
      <Grid>
        <Arrow
          reading={props.reading}
          readingType={props.readingType}
        />
      </Grid>
    </Grid>
  );
}

export default TableCellComponent;

function Arrow(props: Props) {
  const { reading, readingType } = props;

  if (reading) {
    if (readingType === "systolic") {
      if (reading > 120 && reading < 130) {
        return <SignleArrow color="orange" />;
      }
      if (reading >= 130 && reading < 140) {
        return <DoubleArrow color="orange" />;
      }
      if (reading >= 140) {
        return <DoubleArrow color="red" />;
      }
    } else if (readingType === "diastolic") {
      if (reading >= 80 && reading < 90) {
        return <DoubleArrow color="orange" />;
      }
      if (reading >= 90) {
        return <DoubleArrow color="red" />;
      }
    }
  }
  return null;
}

const arrowFontSize = 15;

function SignleArrow(props: { color: string }) {
  return (
    <Box css={arrow}>
      <KeyboardArrowUpIcon sx={{ fontSize: arrowFontSize, color: props.color }} />
    </Box>
  );
}
function DoubleArrow(props: { color: string }) {
  return (
    <Box css={arrow}>
      <KeyboardDoubleArrowUpIcon sx={{ fontSize: arrowFontSize, color: props.color }} />
    </Box>
  );
}
