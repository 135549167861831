import { Box, LinearProgress, useTheme } from "@mui/material";
import LogoIconComponent from "./LogoIcon";

interface Props {
  loading?: boolean;
}

function LogoPageComponent(props: Props) {
  const theme = useTheme();
  const { loading = false } = props;

  return (
    <Box
      sx={{
        display: "grid",
        height: { xs: "calc(100vh - 112px)", sm: "calc(100vh - 64px)" },
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Box>
        <LogoIconComponent
          sx={{ width: 300, height: 300 }}
          themeMode={theme.palette.mode}
        />
        <LinearProgress
          color="secondary"
          sx={{ visibility: loading ? "visible" : "hidden", marginTop: 2 }}
        />
      </Box>
    </Box>
  );
}

export default LogoPageComponent;
