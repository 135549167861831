import { format, parse } from "date-fns";

export const dateStringFormat = process.env.REACT_APP_DATE_FORMAT;
export const timeStringFormat = process.env.REACT_APP_TIME_FORMAT;

export function parseDateString(dateString: string, dateFormat = dateStringFormat) {
  return parse(dateString, dateFormat, new Date());
}

export function formatDate(date: Date, dateFormat = dateStringFormat) {
  return format(date, dateFormat);
}

export function parseTimeString(dateString: string, timeFormat = timeStringFormat) {
  return parse(dateString, timeFormat, new Date());
}

export function formatTime(date: Date, timeFormat = timeStringFormat) {
  return format(date, timeFormat);
}
