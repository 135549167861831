import { Fragment, useEffect } from "react";
import AuthService from "../../services/auth";
import LogoPageComponent from "../../components/logo-page/LogoPage";

const authService = new AuthService();

interface Props {
  inDelay: number;
}

function CognitoAuthComponent(props: Props) {
  useEffect(() => {
    const url = window.location.href;
    setTimeout(() => {
      authService.parseCognitoResponse(url);
    }, props.inDelay);
  }, [props.inDelay]);

  return (
    <Fragment>
      <LogoPageComponent />
    </Fragment>
  );
}

export default CognitoAuthComponent;
