export const translation = {
  translation: {
    index: {
      title: "Blood Pressure Logs",
    },
    appBar: {
      title: "Blood Pressure Monitor Logs",
      locale: "Language",
      appearance: "Appearance",
      about: "About",
      logout: "Logout",
    },
    about: {
      title: "About Blood Pressure Monitor Logs",
      body1: "For any questions, comments or suggestions, please contact me at the following address:",
      body2: "Take care of yourself!",
      ok: "Ok",
    },
    home: {
      add: "Add Reading",
      print: "Print Logs",
      chart: "Logs Chart",
      table: "Logs Table",
      info: "Information",
      howTo: {
        title1: "Welcome!",
        body1:
          "You have not added any blood pressure readings yet. You are free to close this dialog to do so right now. Otherwise, scroll through these slides to learn about the different functionalities of the application.",
        title2: "Adding Readings",
        body2:
          "Click or press on the Add button to insert blood pressure readings and start using the application. You will be able to edit or erase the reading if you need to.",
        title3: "Consult the Readings Table",
        body3:
          "Click or press on the Table button to consult all of your blood pressure reading history. You can select any row in the table to edit or erase a reading. Use the top right filter button in order to only display readings inside a certain date range.",
        title4: "Consult the Readings Graph",
        body4:
          "Click or press the Graph button to visualize your blood pressure reading history. The graph data can also be filtered using the top right button.",
        title5: "Curious about Hypertension?",
        body5:
          "Click or press the Medical Information button to learn about blood pressure and hypertension. You can also find external references to trusted and relevant ressources related to high blood pressure and cardiovascular health.",
        ok: "Got it!",
      },
    },
    filter: {
      title: "Data Filter",
      dateLabel: "Filter by Date Range",
      startDate: "Start Date",
      endDate: "End Date",
      datePickerLabel: "yyyy-mm-dd",
      ok: "Apply",
      cancel: "Remove Filter",
    },
    chart: {
      title: "Readings Chart",
      systolic: "Systolic (mmHg)",
      diastolic: "Diastolic (mmHg)",
    },
    table: {
      title: "Reading Logs",
      date: "Date",
      time: "Time",
      systolic: "Systolic (mmHg)",
      diastolic: "Diastolic (mmHg)",
      systolicMobile: "Sys. (mmHg)",
      diastolicMobile: "Dia. (mmHg)",
      pulse: "Pulse (bpm)",
      note: "Notes",
      average: "Average:",
    },
    info: {
      title: "Hypertension",
      header1: "What is Blood Pressure?",
      body1_1:
        "Blood pressure is the measure of the amount of force brought by the circulation of blood against the inner walls of the body's arteries. Blood pressure readings are detailed by two measures. The first and highest number is the systolic pressure, which is the pressure created when the heart contracts and pushes blood all over your body. The second and lowest number is the diastolic pressure, which represents the pressure in your blood vessels when the heart is at rest between beats.",
      header2: "What is Hypertension?",
      body2_1:
        "Hypertension is the condition where your average blood pressure at rest is over a certain threshold. If your readings are consistently greater than 140 for the systolic pressure and 90 for the diastolic pressure, or 130 and 80 if you suffer from diabetes or a kidney disease, you are considered to have hypertension. Your optimal blood pressure should be around 120 and 80.",
      body2_2:
        "Because hypertension doesn't cause many noticeable symptoms before creating major health problems, it is crucial that you monitor your blood pressure constantly at home if you suspect you are suffering from this condition, using a blood pressure monitor. While it majoritarily affects people over 55 years old, young adults are not immune to this condition.",
      header3: "What are the Complications of Hypertension?",
      body3_1:
        "While apparent symptoms may never be felt, hypertension is a serious medical condition which significantly increases the risk of heart diseases and strokes. Blood pressure above the pre-hypertension threshold of 130 and 85 is associated with a 2.5 times greater risk of cardiovascular diseases in women and 1.6 in men.",
      body3_2:
        "According to Health Canada, about one in five adults are living with diagnosed hypertension. The true prevalence of hypertension is still likely higher as appromimately 17% of individuals are not aware that their blood pressure is too high.",
      header4: "How can blood pressure be reduced?",
      body4_1:
        "Hypertension is due to multiple factors, some of which are out of your control, including your age, your ethnicity, your genetic predispositions as well as your sex. On the other hand, lifestyle choices like nutrition, sedentarity and substance abuse can also influence your blood pressure.",
      body4_2:
        "If you determine that your blood pressure is above the pre-hypertension threshold of 130 and 85, please consult your physicians in order to effectively have a follow up on your condition. While medication can be prescribed in order to control hypertension, changes in the way you live can also improve your blood pressures. Reducing sodium intake, especially from processed food, reducing fat intake, eating more vegetables and fruit and being more physically active will go a long way to improve your health and control your blood pressure.",
      header5: "External Resources",
      body5_1: "Government of Canada's Hypertension Resources",
      link1:
        "https://www.canada.ca/en/public-health/services/chronic-diseases/cardiovascular-disease/hypertension.html",
      body5_2: "Hypertension Canada",
      link2: "https://hypertension.ca/",
      body5_3: "Recommended Blood Pressure Monitor device list by Hypertension Canada",
      link3: "https://hypertension.ca/bpdevices",
      body5_4: "Heart & Stroke Canada",
      link4: "https://www.heartandstroke.ca/",
      body5_5: "Montreal Heart Institute",
      link5: "https://www.icm-mhi.org/en",
    },
    readingForm: {
      createTitle: "New Reading",
      updateTitle: "Edit Reading",
      date: "Date",
      time: "Time",
      systolic: "Systolic (mmHg)",
      diastolic: "Diastolic (mmHg)",
      pulse: "Pulse (bpm)",
      note: "Notes",
      createOk: "Add",
      editOk: "Save",
      cancel: "Cancel",
      delete: "Delete",
      confirmationMessage: "Are you sure you wish to delete the selected reading?",
    },
    confirmationDialog: {
      title: "Warning",
      ok: "Yes",
      cancel: "No",
    },
    errorDialog: {
      title: "Error",
      ok: "Ok",
    },
    pwaUpdate: {
      message: "A new version of the application is available!",
      reload: "Reload",
    },
  },
};
